import React, { useState, useEffect } from 'react';
import { Star, Edit2, Trash2, Plus } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
import { ReviewService } from '../../services/api';

const ReviewsManagement = () => {
  const [reviews, setReviews] = useState([]);
  const [showAddReview, setShowAddReview] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Form state for new/edit review
  const [formData, setFormData] = useState({
    rating: 5,
    title: '',
    content: '',
    visitDate: new Date().toISOString().split('T')[0],
    wineryId: ''
  });

  useEffect(() => {
    fetchUserReviews();
  }, []);

  const fetchUserReviews = async () => {
    try {
      const response = await fetch('/api/user/reviews');
      if (!response.ok) throw new Error('Failed to fetch reviews');
      const data = await response.json();
      setReviews(data);
    } catch (err) {
      setError('Failed to load reviews');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitReview = async (e) => {
    e.preventDefault();
    try {
      const formattedData = ReviewService.formatReviewData(formData);
      const response = await ReviewService.createReview(formData.wineryId, formattedData);

      if (!response.ok) throw new Error('Failed to submit review');

      // Refresh reviews list
      fetchUserReviews();
      setShowAddReview(false);
      setFormData({
        rating: 5,
        title: '',
        content: '',
        visitDate: new Date().toISOString().split('T')[0],
        wineryId: ''
      });
    } catch (err) {
      setError('Failed to submit review');
    }
  };

  const handleDeleteReview = async (reviewId) => {
    if (!window.confirm('Are you sure you want to delete this review?')) return;

    try {
      const response = await fetch(`/api/reviews/${reviewId}`, {
        method: 'DELETE'
      });

      if (!response.ok) throw new Error('Failed to delete review');

      // Remove review from state
      setReviews(reviews.filter(review => review._id !== reviewId));
    } catch (err) {
      setError('Failed to delete review');
    }
  };

  const StarRating = ({ rating, onRatingChange }) => {
    return (
      <div className="flex gap-1">
        {[1, 2, 3, 4, 5].map((star) => (
          <button
            key={star}
            type="button"
            onClick={() => onRatingChange?.(star)}
            className={`text-2xl ${star <= (rating || formData.rating) ? 'text-yellow-400' : 'text-gray-300'
              }`}
          >
            <Star className="w-6 h-6" fill={star <= (rating || formData.rating) ? 'currentColor' : 'none'} />
          </button>
        ))}
      </div>
    );
  };

  if (loading) {
    return <div className="text-center p-4">Loading...</div>;
  }

  return (
    <div className="space-y-6 p-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">My Reviews</h2>
        <button
          onClick={() => setShowAddReview(true)}
          className="flex items-center gap-2 px-4 py-2 bg-[#822727] text-white rounded-lg hover:bg-[#722323]"
        >
          <Plus className="w-4 h-4" />
          Add Review
        </button>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
          {error}
        </div>
      )}

      {showAddReview && (
        <Card>
          <CardHeader>
            <CardTitle>Write a Review</CardTitle>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmitReview} className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-1">Rating</label>
                <StarRating
                  rating={formData.rating}
                  onRatingChange={(value) => setFormData({ ...formData, rating: value })}
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Winery</label>
                <select
                  value={formData.wineryId}
                  onChange={(e) => setFormData({ ...formData, wineryId: e.target.value })}
                  className="w-full px-3 py-2 border rounded-lg"
                  required
                >
                  <option value="">Select a winery</option>
                  {/* Add winery options here */}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Title</label>
                <input
                  type="text"
                  value={formData.title}
                  onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                  className="w-full px-3 py-2 border rounded-lg"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Review</label>
                <textarea
                  value={formData.content}
                  onChange={(e) => setFormData({ ...formData, content: e.target.value })}
                  className="w-full px-3 py-2 border rounded-lg"
                  rows={4}
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Visit Date</label>
                <input
                  type="date"
                  value={formData.visitDate}
                  onChange={(e) => setFormData({ ...formData, visitDate: e.target.value })}
                  className="w-full px-3 py-2 border rounded-lg"
                  required
                />
              </div>

              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  onClick={() => setShowAddReview(false)}
                  className="px-4 py-2 border rounded-lg hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-[#822727] text-white rounded-lg hover:bg-[#722323]"
                >
                  Submit Review
                </button>
              </div>
            </form>
          </CardContent>
        </Card>
      )}

      <div className="grid gap-6">
        {reviews.map((review) => (
          <Card key={review._id}>
            <CardContent className="pt-6">
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-lg font-semibold">{review.title}</h3>
                  <StarRating rating={review.rating} />
                  <p className="text-sm text-gray-500 mt-1">
                    {new Date(review.visitDate).toLocaleDateString()}
                  </p>
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() => {
                      setFormData({
                        ...review,
                        wineryId: review.winery._id
                      });
                      setShowAddReview(true);
                    }}
                    className="p-2 hover:bg-gray-100 rounded-lg"
                  >
                    <Edit2 className="w-4 h-4" />
                  </button>
                  <button
                    onClick={() => handleDeleteReview(review._id)}
                    className="p-2 hover:bg-gray-100 rounded-lg text-red-600"
                  >
                    <Trash2 className="w-4 h-4" />
                  </button>
                </div>
              </div>
              <p className="mt-4">{review.content}</p>
              {review.response && (
                <div className="mt-4 bg-gray-50 p-4 rounded-lg">
                  <p className="text-sm font-semibold">Response from {review.winery.name}</p>
                  <p className="mt-2">{review.response.content}</p>
                </div>
              )}
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

// Add to navigation in UserDashboard
export const reviewsConfig = {
  path: '/reviews',
  icon: Star,
  label: 'My Reviews'
};

export default ReviewsManagement;