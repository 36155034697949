import React, { useState } from 'react';
import { Menu, X, Plus, Calendar, Star, MapPin, Globe, Wine, Heart, LogOut, Scan } from 'lucide-react';
import InteractiveMap from '../InteractiveMap';
import MapFilters from '../MapFilters';
import { useWineries } from '../../hooks/useWineries';
import { useAuth } from '../../context/AuthContext';
import WineDetails from '../WineDetails';
import FavouritesComponent from '../FavouritesComponent';

const InteractiveDashboard = () => {
    const [showMenu, setShowMenu] = useState(true);
    const [activePanel, setActivePanel] = useState(null);
    const [dragStartY, setDragStartY] = useState(null);
    const [panelOffset, setPanelOffset] = useState(0);
    const { wineries, loading, error, filters, updateFilters } = useWineries();
    const { user, role, logout } = useAuth();
    const [formData, setFormData] = useState({
        name: '',
        type: '',
        vintage: new Date().getFullYear(),
        varietal: '',
        price: '',
        description: '',
        wineryId: '',
        technicalDetails: {
            alcohol: '',
            pH: '',
            acidity: '',
            residualSugar: ''
        },
        frontLabel: null,
        backLabel: null
    });

    return (
        <div className="h-screen relative">
            {/* Interactive Map */}
            <div className="fixed inset-0 w-screen h-screen">
                <InteractiveMap className="w-full h-full" />
            </div>

            {/* Top Bar */}
            <div className="absolute top-14 left-0 right-0 p-4 flex justify-between items-center z-10">
                <button
                    onClick={() => setShowMenu(true)}
                    className="p-3 bg-white rounded-full shadow-lg hover:bg-gray-50"
                >
                    <Menu className="w-6 h-6" />
                </button>
            </div>

            {/* Map Filters will automatically handle search and filtering */}
            <div className="flex-1">
                <MapFilters
                    filters={filters}
                    onChange={updateFilters}
                    name="absolute top-4 left-4 z-10 right-14"
                />
            </div>

            {/* Sliding Panels */}
            {activePanel && <div
                style={{
                    zIndex: 100,
                    backgroundColor: 'white',
                }}
            >
                {/* Panel Content */}
                <div className="overflow-y-auto h-[calc(90vh-4rem)] pb-20">
                    <div className="p-6">
                        {activePanel === 'review' && (
                            <FavouritesComponent onClose={() => setActivePanel(null)} />
                        )}
                        {activePanel === 'scan' && (
                            <WineDetails onClose={() => setActivePanel(null)} />
                        )}
                    </div>
                </div>

                {/* Close button */}
                <button
                    onClick={() => setActivePanel(null)}
                    className="absolute top-4 right-4 p-2 hover:bg-gray-100 rounded-full"
                >
                    <X className="w-6 h-6" />
                </button>
            </div>}

            {/* Bottom Navigation Bar */}
            {<div style={{backgroundColor: '#f0d9d9'}} className="fixed bottom-0 left-0 right-0 shadow-lg z-50">
                <div className="max-w-md mx-auto flex justify-around items-center h-16">
                    <button
                        onClick={() => setActivePanel(null)}
                        className={`flex flex-col items-center justify-center flex-1 h-full ${!activePanel ? 'text-[#822727]' : 'text-gray-500'}`}
                    >
                        <Globe className="w-6 h-6" />
                        <span className="text-xs mt-1">Explore</span>
                    </button>

                    <button
                        onClick={() => setActivePanel('scan')}
                        className="flex flex-col items-center justify-center flex-1 h-full relative"
                    >
                        <div className={`absolute -top-6 w-14 h-14 rounded-full flex items-center justify-center shadow-lg ${activePanel === 'scan' ? 'bg-[#722323]' : 'bg-[#822727]'
                            }`}>
                            <Wine className="w-7 h-7 text-white" />
                        </div>
                        <span className={`text-xs mt-8 ${activePanel === 'scan' ? 'text-[#822727]' : 'text-gray-500'}`}>Search</span>
                    </button>

                    <button
                        onClick={() => setActivePanel('review')}
                        className={`flex flex-col items-center justify-center flex-1 h-full ${activePanel === 'review' ? 'text-[#822727]' : 'text-gray-500'
                            }`}
                    >
                        <Heart className="w-6 h-6" />
                        <span className="text-xs mt-1">Favorites</span>
                    </button>
                </div>
            </div>}

            {/* Slide-out Menu */}
            <div className={`fixed inset-y-0 left-0 w-80 bg-white shadow-2xl transform 
        transition-transform duration-300 ease-in-out z-50 ${showMenu ? 'translate-x-0' : '-translate-x-full'
                }`}
            >
                <div className="p-6">
                    <div className="flex justify-between items-center mb-8">
                        <h2 className="text-2xl font-bold">DrinkWine</h2>
                        <button
                            onClick={() => setShowMenu(false)}
                            className="p-2 hover:bg-gray-100 rounded-full"
                        >
                            <X className="w-6 h-6" />
                        </button>
                    </div>

                    <div className="space-y-6">
                        {/* User Profile */}
                        <div className="flex items-center gap-4 mb-8">
                            <div className="w-16 h-16 rounded-full bg-[#822727] text-white 
                flex items-center justify-center text-xl">
                                A
                            </div>
                            <div>
                                <h3 className="font-bold">{user.displayName || `${user.email}`}</h3>
                                <p className="text-sm text-gray-600">{role}</p>
                            </div>
                        </div>

                        {/* Navigation Links */}
                        <nav className="space-y-1">
                            <button
                                onClick={logout}
                                className="w-full flex items-center justify-between p-4 hover:bg-gray-50 
              rounded-lg transition-colors"
                            >
                                <LogOut size={20} />
                                <span>Logout</span>
                            </button>
                        </nav>

                        {/* Quick Stats */}
                        {false && <div className="grid grid-cols-3 gap-4 mb-8">
                            <div className="text-center p-3 bg-gray-50 rounded-lg">
                                <div className="text-2xl font-bold">12</div>
                                <div className="text-sm text-gray-600">Reviews</div>
                            </div>
                            <div className="text-center p-3 bg-gray-50 rounded-lg">
                                <div className="text-2xl font-bold">8</div>
                                <div className="text-sm text-gray-600">Wineries</div>
                            </div>
                            <div className="text-center p-3 bg-gray-50 rounded-lg">
                                <div className="text-2xl font-bold">4</div>
                                <div className="text-sm text-gray-600">Bookings</div>
                            </div>
                        </div>}

                        {/* Recent Activity */}
                        {false && <div>
                            <h3 className="font-bold mb-4">Recent Activity</h3>
                            <div className="space-y-4">
                                {[
                                    {
                                        action: 'Booked a tasting',
                                        place: 'Graham Beck',
                                        time: '2 hours ago'
                                    },
                                    {
                                        action: 'Left a review',
                                        place: 'Springfield Estate',
                                        time: '1 day ago'
                                    }
                                ].map((activity, index) => (
                                    <div key={index} className="text-sm">
                                        <p className="font-medium">{activity.action}</p>
                                        <p className="text-gray-600">{activity.place}</p>
                                        <p className="text-gray-400 text-xs">{activity.time}</p>
                                    </div>
                                ))}
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InteractiveDashboard;