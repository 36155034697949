import React, { useState } from 'react';

const DataDeletion = () => {
  const [requestSubmitted, setRequestSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmitRequest = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Simulate API call
    setTimeout(() => {
      setRequestSubmitted(true);
      setLoading(false);
    }, 1000);
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white shadow rounded-lg p-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Data Deletion Request</h1>
        
        <div className="space-y-6 text-gray-600">
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Your Data Privacy Rights</h2>
            <p>We respect your right to control your data. You can request the deletion of your personal information from DrinkWine at any time.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">What Will Be Deleted</h2>
            <p>When you request data deletion, we will remove:</p>
            <ul className="list-disc pl-5 space-y-2">
              <li>Your account information</li>
              <li>Profile details</li>
              <li>Booking history</li>
              <li>Reviews and ratings</li>
              <li>Saved preferences</li>
              <li>Communication history</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Data Retention</h2>
            <p>Some information may be retained for:</p>
            <ul className="list-disc pl-5 space-y-2">
              <li>Legal compliance</li>
              <li>Financial record keeping</li>
              <li>Service improvement analytics (anonymized)</li>
            </ul>
          </section>

          {!requestSubmitted ? (
            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Submit Deletion Request</h2>
              <p className="mb-4">To request the deletion of your data, please confirm below:</p>
              
              <form onSubmit={handleSubmitRequest} className="space-y-4">
                <div className="flex items-start">
                  <input
                    id="confirm"
                    name="confirm"
                    type="checkbox"
                    required
                    className="mt-1 h-4 w-4 rounded border-gray-300 text-[#822727] focus:ring-[#822727]"
                  />
                  <label htmlFor="confirm" className="ml-2 block text-sm text-gray-700">
                    I understand that this action cannot be undone and will permanently delete my account and associated data.
                  </label>
                </div>
                
                <button
                  type="submit"
                  disabled={loading}
                  className={`flex w-full justify-center rounded-md bg-[#822727] px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#722323] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#822727] transition-colors
                    ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  {loading ? 'Processing...' : 'Request Data Deletion'}
                </button>
              </form>
            </section>
          ) : (
            <section className="bg-green-50 border border-green-200 rounded-md p-4">
              <h2 className="text-xl font-semibold text-green-800 mb-2">Request Submitted Successfully</h2>
              <p className="text-green-700">
                We have received your data deletion request. We will process your request within 30 days and send you a confirmation email once completed.
              </p>
            </section>
          )}

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Alternative Methods</h2>
            <p>You can also request data deletion by:</p>
            <ul className="list-disc pl-5 space-y-2">
              <li>Emailing our Privacy Team at privacy@drinkwine.co.za</li>
              <li>Sending a written request to our postal address:
                <br />
                Privacy Department
                <br />
                DrinkWine
                <br />
                Robertson Valley
                <br />
                South Africa
              </li>
              <li>Calling our Privacy Support Line: +27 83 123 4567</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Processing Timeline</h2>
            <p>Once we receive your request:</p>
            <ul className="list-disc pl-5 space-y-2">
              <li>We will verify your identity within 2 business days</li>
              <li>Data deletion will begin within 7 business days</li>
              <li>Complete deletion will be finished within 30 days</li>
              <li>You will receive email confirmation at each step</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Third-Party Data</h2>
            <p>Please note that:</p>
            <ul className="list-disc pl-5 space-y-2">
              <li>If you used social login (Facebook/Google), you should also review their privacy settings</li>
              <li>Public reviews may be retained in anonymized form</li>
              <li>Some data may be retained in backup systems for up to 60 days</li>
            </ul>
          </section>

          <div className="mt-8 text-sm text-gray-500">
            <p>Last updated: January 18, 2025</p>
            <p>For more information, please review our <a href="/privacy-policy" className="text-[#822727] hover:text-[#722323]">Privacy Policy</a>.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataDeletion;