import React, { useState, useEffect, useCallback } from 'react';
import Map, { Marker, NavigationControl } from 'react-map-gl';
import _ from 'lodash';
import {
  Plus, Search, Edit, Trash2, MapPin, Phone, Clock, Wine,
  Filter, Download, Upload, X, AlertCircle
} from 'lucide-react';
import { WineryService } from '../../services/api';
import { ServiceAPI } from '../../services/url';

const MAPBOX_TOKEN = "pk.eyJ1Ijoiam9oYW5iZWhyZW5zIiwiYSI6ImNtNGdtYjdrMTFnaG4ya3IzejA0ajk4dmwifQ.gAd4vI3A3oHjUwmOOvJKWQ";

const INITIAL_VIEW_STATE = {
  longitude: 19.8842,
  latitude: -33.8012,
  zoom: 12,
  pitch: 0,
  bearing: 0
};

const API_BASE_URL = ServiceAPI;

// Error Alert Component
const ErrorAlert = ({ message, onClose }) => (
  <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-4">
    <div className="flex items-center">
      <div className="flex-shrink-0">
        <AlertCircle className="h-5 w-5 text-red-400" />
      </div>
      <div className="ml-3">
        <p className="text-sm text-red-700">{message}</p>
      </div>
      {onClose && (
        <button
          className="ml-auto pl-3"
          onClick={onClose}
        >
          <X className="h-5 w-5 text-red-400" />
        </button>
      )}
    </div>
  </div>
);

// Location Picker Component

// In LocationPicker component, update view state when coordinates change
const LocationPicker = ({ value, onChange }) => {
  const [viewState, setViewState] = useState(
    value ? {
      longitude: value[0],
      latitude: value[1],
      zoom: 14,
      pitch: 0,
      bearing: 0
    } : {
      longitude: 19.8842,
      latitude: -33.8012,
      zoom: 12,
      pitch: 0,
      bearing: 0
    }
  );

  useEffect(() => {
    if (value) {
      setViewState(prev => ({
        ...prev,
        longitude: value[0],
        latitude: value[1],
        zoom: 14
      }));
    }
  }, [value]);

  const handleMapClick = useCallback((event) => {
    const { lng, lat } = event.lngLat;
    onChange([lng, lat]);
    setViewState(prev => ({
      ...prev,
      longitude: lng,
      latitude: lat
    }));
  }, [onChange]);

  return (
    <div className="h-96 rounded-lg overflow-hidden">
      <Map
        {...viewState}
        onMove={evt => setViewState(evt.viewState)}
        mapStyle="mapbox://styles/mapbox/outdoors-v12"
        mapboxAccessToken={MAPBOX_TOKEN}
        onClick={handleMapClick}
      >
        <NavigationControl position="top-right" />
        <Marker
          longitude={value ? value[0] : viewState.longitude}
          latitude={value ? value[1] : viewState.latitude}
          draggable
          onDragEnd={(event) => {
            const { lng, lat } = event.lngLat;
            onChange([lng, lat]);
          }}
        >
          <MapPin className="w-6 h-6 text-[#822727]" />
        </Marker>
      </Map>
    </div>
  );
};

const FeatureInput = ({ features, onFeaturesChange }) => {
  const [newFeature, setNewFeature] = useState('');

  const handleAddFeature = (e) => {
    e.preventDefault();
    if (newFeature.trim()) {
      onFeaturesChange([...features, newFeature.trim()]);
      setNewFeature('');
    }
  };

  const handleRemoveFeature = (indexToRemove) => {
    onFeaturesChange(features.filter((_, index) => index !== indexToRemove));
  };

  return (
    <div className="space-y-4">
      {/* Existing Features List */}
      <div className="flex flex-wrap gap-2">
        {features.map((feature, index) => (
          <div
            key={index}
            className="flex items-center gap-2 px-3 py-1 bg-gray-100 rounded-full group hover:bg-gray-200"
          >
            <span>{feature}</span>
            <button
              type="button"
              onClick={() => handleRemoveFeature(index)}
              className="text-gray-400 hover:text-red-500 opacity-0 group-hover:opacity-100 transition-opacity"
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        ))}
      </div>

      {/* Add New Feature Form */}
      <form onSubmit={handleAddFeature} className="flex gap-2">
        <input
          type="text"
          value={newFeature}
          onChange={(e) => setNewFeature(e.target.value)}
          placeholder="Add a new feature..."
          className="flex-1 px-3 py-2 border rounded-lg focus:ring-[#822727] focus:border-[#822727]"
        />
        <button
          type="submit"
          disabled={!newFeature.trim()}
          className="px-4 py-2 bg-[#822727] text-white rounded-lg hover:bg-[#722323] disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Add
        </button>
      </form>
    </div>
  );
};

const RegionSelect = ({ value, onChange, className }) => {
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [customRegion, setCustomRegion] = useState('');
  const [isCustom, setIsCustom] = useState(false);

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        setLoading(true);
        const response = await WineryService.getDistinctRegions();
        setRegions(response.regions);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRegions();
  }, []);

  useEffect(() => {
    // Check if current value exists in regions
    if (value && !regions.includes(value)) {
      setIsCustom(true);
      setCustomRegion(value);
    }
  }, [value, regions]);

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === 'custom') {
      setIsCustom(true);
      setCustomRegion('');
    } else {
      setIsCustom(false);
      onChange(selectedValue);
    }
  };

  const handleCustomInputChange = (e) => {
    const value = e.target.value;
    setCustomRegion(value);
    onChange(value);
  };

  if (loading) {
    return <div className="animate-pulse h-10 bg-gray-200 rounded"></div>;
  }

  if (error) {
    return <div className="text-red-500">Error loading regions</div>;
  }

  return (
    <div className="space-y-2">
      <select
        value={isCustom ? 'custom' : value}
        onChange={handleSelectChange}
        className={className}
      >
        <option value="">Select Region</option>
        {regions.map((region) => (
          <option key={region} value={region}>
            {region}
          </option>
        ))}
        <option value="custom">Add New Region</option>
      </select>

      {isCustom && (
        <input
          type="text"
          value={customRegion}
          onChange={handleCustomInputChange}
          placeholder="Enter new region"
          className={className}
        />
      )}
    </div>
  );
};

const WineryForm = ({ winery, onSave, onCancel }) => {
  const [formData, setFormData] = useState(winery || {
    name: '',
    region: '',
    description: '',
    location: {
      address: '',
      coordinates: [INITIAL_VIEW_STATE.longitude, INITIAL_VIEW_STATE.latitude]
    },
    features: [],
    operatingHours: {
      monday: { isOpen: true, open: '09:00', close: '17:00' },
      tuesday: { isOpen: true, open: '09:00', close: '17:00' },
      wednesday: { isOpen: true, open: '09:00', close: '17:00' },
      thursday: { isOpen: true, open: '09:00', close: '17:00' },
      friday: { isOpen: true, open: '09:00', close: '17:00' },
      saturday: { isOpen: true, open: '10:00', close: '15:00' },
      sunday: { isOpen: false, open: '10:00', close: '15:00' }
    },
    contact: {
      phone: '',
      email: '',
      website: ''
    }
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      let response = {};
      if (winery) {
        response = await WineryService.updateWinery(winery._id, formData);
      } else {
        response = await WineryService.createWinery(formData);
      }

      if (response.name) {
        onSave();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to save winery');
      }

    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow p-4 md:p-6">
      {error && <ErrorAlert message={error} onClose={() => setError('')} />}

      <div className="space-y-6">
        {/* Basic Information */}
        <div>
          <h3 className="text-lg font-medium mb-4">Basic Information</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium mb-1">Winery Name</label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                className="w-full px-3 py-2 border rounded-lg"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Region</label>
              <RegionSelect
                value={formData.region}
                onChange={(value) => setFormData(prev => ({ ...prev, region: value }))}
                className="w-full px-3 py-2 border rounded-lg focus:ring-[#822727] focus:border-[#822727]"
              />
            </div>
            <div className="col-span-2">
              <label className="block text-sm font-medium mb-1">Address</label>
              <input
                type="text"
                value={formData.location?.address}
                onChange={(e) => setFormData(prev => ({ ...prev, location: { ...prev.location, address: e.target.value } }))}
                className="w-full px-3 py-2 border rounded-lg"
                required
              />
            </div>
            <div className="col-span-2">
              <label className="block text-sm font-medium mb-1">Location</label>
              <LocationPicker
                value={formData.location?.coordinates}
                onChange={(coordinates) => setFormData(prev => ({ ...prev, location: { ...prev.location, coordinates } }))}
              />
            </div>
            <div className="col-span-2">
              <label className="block text-sm font-medium mb-1">Description</label>
              <textarea
                value={formData.description}
                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                className="w-full px-3 py-2 border rounded-lg"
                rows={4}
                required
              />
            </div>
          </div>
        </div>

        {/* Contact Information */}
        <div>
          <h3 className="text-lg font-medium mb-4">Contact Information</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium mb-1">Phone</label>
              <input
                type="tel"
                value={formData.contact?.phone}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  contact: { ...prev.contact, phone: e.target.value }
                }))}
                className="w-full px-3 py-2 border rounded-lg"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Email</label>
              <input
                type="email"
                value={formData.contact?.email}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  contact: { ...prev.contact, email: e.target.value }
                }))}
                className="w-full px-3 py-2 border rounded-lg"
                required
              />
            </div>
            <div className="col-span-2">
              <label className="block text-sm font-medium mb-1">Website</label>
              <input
                type="url"
                value={formData.contact?.website}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  contact: { ...prev.contact, website: e.target.value }
                }))}
                className="w-full px-3 py-2 border rounded-lg"
              />
            </div>
          </div>
        </div>

        {/* Operating Hours */}
        <div>
          <h3 className="text-lg font-medium mb-4">Operating Hours</h3>
          <div className="space-y-3">
            {Object.entries(formData.operatingHours).map(([day, hours]) => (
              <div key={day} className="flex items-center gap-4">
                <div className="w-32 capitalize">{day}</div>
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={hours.isOpen}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      operatingHours: {
                        ...prev.operatingHours,
                        [day]: { ...hours, isOpen: e.target.checked }
                      }
                    }))}
                    className="rounded text-[#822727]"
                  />
                  <span>Open</span>
                </label>
                {hours.isOpen && (
                  <>
                    <input
                      type="time"
                      value={hours.open}
                      onChange={(e) => setFormData(prev => ({
                        ...prev,
                        operatingHours: {
                          ...prev.operatingHours,
                          [day]: { ...hours, open: e.target.value }
                        }
                      }))}
                      className="border rounded px-2 py-1"
                    />
                    <span>to</span>
                    <input
                      type="time"
                      value={hours.close}
                      onChange={(e) => setFormData(prev => ({
                        ...prev,
                        operatingHours: {
                          ...prev.operatingHours,
                          [day]: { ...hours, close: e.target.value }
                        }
                      }))}
                      className="border rounded px-2 py-1"
                    />
                  </>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Features */}
        <div>
          <h3 className="text-lg font-medium mb-4">Features</h3>
          <FeatureInput
            features={formData.features}
            onFeaturesChange={(newFeatures) =>
              setFormData(prev => ({ ...prev, features: newFeatures }))
            }
          />
        </div>

        {/* Buttons */}
        <div className="flex justify-end gap-4">
          <button
            type="button"
            onClick={onCancel}
            disabled={loading}
            className="px-4 py-2 border rounded-lg hover:bg-gray-50 disabled:opacity-50"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading}
            className="px-4 py-2 bg-[#822727] text-white rounded-lg hover:bg-[#722323] disabled:opacity-50"
          >
            {loading ? 'Saving...' : (winery ? 'Update Winery' : 'Create Winery')}
          </button>
        </div>
      </div>
    </form>
  );
};

const WineryList = ({ onEdit, search = "" }) => {
  const [wineries, setWineries] = useState([]);
  const [filters, setFilters] = useState({
    region: '',
    features: []
  });
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!loading) fetchWineries();
  }, [search, filters, pagination.currentPage]);

  const fetchWineries = async () => {
    try {
      setLoading(true);
      setError('');
      const queryParams = new URLSearchParams({
        page: pagination.currentPage,
        limit: 10,
        search: search,
        ...filters
      });

      const response = await fetch(`${API_BASE_URL}/wineries?${queryParams}`);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error fetching wineries');
      }

      const data = await response.json();
      setWineries(data.wineries);
      setPagination({
        currentPage: data.currentPage,
        totalPages: data.totalPages,
        total: data.total
      });
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this winery?')) {
      try {
        setError('');
        const response = await WineryService.deleteWinery(id);

        if (response?.message) {
          fetchWineries();
        }

      } catch (error) {
        setError(error.message);
      }
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#822727]"></div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow">
      {error && <ErrorAlert message={error} onClose={() => setError('')} />}


      {/* Wineries List */}
      <div className="divide-y">
        {wineries.length === 0 ? (
          <div className="p-4 text-center text-gray-500">
            No wineries found
          </div>
        ) : (
          wineries.map(winery => (
            <div key={winery.id} className="p-4 hover:bg-gray-50">
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="font-semibold text-lg">{winery.name}</h3>
                  <div className="flex items-center gap-2 text-sm text-gray-600 mt-1">
                    <MapPin className="w-4 h-4" />
                    <span>{winery.location?.address}</span>
                  </div>
                  <div className="flex items-center gap-2 text-sm text-gray-600 mt-1">
                    <Phone className="w-4 h-4" />
                    <span>{winery.contact?.phone}</span>
                  </div>
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() => onEdit(winery)}
                    className="p-2 hover:bg-gray-100 rounded-lg"
                  >
                    <Edit className="w-4 h-4" />
                  </button>
                  <button
                    onClick={() => handleDelete(winery._id)}
                    className="p-2 hover:bg-gray-100 rounded-lg text-red-600"
                  >
                    <Trash2 className="w-4 h-4" />
                  </button>
                </div>
              </div>
              <div className="mt-3 flex flex-wrap gap-2">
                {winery.features?.map((feature, index) => (
                  <span
                    key={index}
                    className="px-2 py-1 bg-gray-100 rounded-full text-xs"
                  >
                    {feature}
                  </span>
                ))}
              </div>
            </div>
          ))
        )}
      </div>

      {/* Pagination */}
      <div className="px-3 md:px-6 py-4 flex flex-col md:flex-row items-center justify-between border-t space-y-3 md:space-y-0">
        <div className="text-sm text-gray-700 text-center md:text-left">
          Showing{' '}
          <span className="font-medium">
            {((pagination.currentPage - 1) * 10) + 1}
          </span>{' '}
          to{' '}
          <span className="font-medium">
            {Math.min(pagination.currentPage * 10, pagination.total)}
          </span>{' '}
          of{' '}
          <span className="font-medium">{pagination.total}</span>{' '}
          results
        </div>
        <div className="flex flex-wrap justify-center items-center gap-2">
          <button
            onClick={() => setPagination(prev => ({ ...prev, currentPage: prev.currentPage - 1 }))}
            disabled={pagination.currentPage === 1}
            className={`px-3 py-1 rounded border ${pagination.currentPage === 1
              ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
              : 'hover:bg-gray-50'
              }`}
          >
            Previous
          </button>
          {Array.from({ length: pagination.totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => setPagination(prev => ({ ...prev, currentPage: page }))}
              className={`px-3 py-1 rounded border ${page === pagination.currentPage
                ? 'bg-[#822727] text-white'
                : 'hover:bg-gray-50'
                }`}
            >
              {page}
            </button>
          ))}
          <button
            onClick={() => setPagination(prev => ({ ...prev, currentPage: prev.currentPage + 1 }))}
            disabled={pagination.currentPage === pagination.totalPages}
            className={`px-3 py-1 rounded border ${pagination.currentPage === pagination.totalPages
              ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
              : 'hover:bg-gray-50'
              }`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

const WineryManagement = () => {
  const [showForm, setShowForm] = useState(false);
  const [selectedWinery, setSelectedWinery] = useState(null);
  const [error, setError] = useState('');
  const [search, setSearch] = useState('');
  const [dsearch, setDSearch] = useState('');
  const debouncedSearch = _.debounce(setDSearch, 300);
  const [mapViewState, setMapViewState] = useState({
    longitude: 19.8842,
    latitude: -33.8012,
    zoom: 12,
    pitch: 0,
    bearing: 0
  });

  const handleInputChange = (term) => {
    setSearch(term);
    debouncedSearch(term);
  };

  useEffect(() => {
    if (selectedWinery && selectedWinery.location?.coordinates) {
      const [longitude, latitude] = selectedWinery.location.coordinates;
      setMapViewState(prev => ({
        ...prev,
        longitude,
        latitude,
        zoom: 14 // Zoom in closer to see the winery clearly
      }));
    }
  }, [selectedWinery]);


  const handleEdit = (winery) => {
    setSelectedWinery(winery);
    setShowForm(true);
  };

  const handleSave = () => {
    setShowForm(false);
    setSelectedWinery(null);
    setError('');
  };

  const handleCancel = () => {
    setShowForm(false);
    setSelectedWinery(null);
    setError('');
  };

  return (
    <div className="p-3 md:p-6">
      <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-3 md:space-y-0">
        <h2 className="text-xl md:text-2xl font-bold">Winery Management</h2>
        <button
          onClick={() => setShowForm(true)}
          className="w-full md:w-auto flex items-center justify-center gap-2 px-4 py-2 bg-[#822727] text-white rounded-lg hover:bg-[#722323]"
        >
          <Plus className="w-4 h-4" />
          Add Winery
        </button>
      </div>

      {error && <ErrorAlert message={error} onClose={() => setError('')} />}

      {showForm ? (
        <WineryForm
          winery={selectedWinery}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      ) : (
        <>
          {/* Search and Filters */}
          <div className="p-3 md:p-4 border-b">
            <div className="flex flex-col md:flex-row gap-4 mb-4">
              <div className="flex-1 relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
                <input
                  type="text"
                  placeholder="Search wineries..."
                  value={search}
                  onChange={(e) => handleInputChange(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border rounded-lg"
                />
              </div>
              {/* Rest of the filters remain the same */}
            </div>
          </div>
          <WineryList onEdit={handleEdit} search={dsearch} />
        </>
      )}
    </div>
  );
};

export default WineryManagement;