import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white shadow rounded-lg p-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Privacy Policy</h1>
        
        <div className="space-y-6 text-gray-600">
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">1. Information We Collect</h2>
            <p className="mb-4">We collect information that you provide directly to us:</p>
            <ul className="list-disc pl-5 space-y-2">
              <li>Account information (name, email, phone number)</li>
              <li>Profile information (preferences, interests)</li>
              <li>Transaction information (bookings, payments)</li>
              <li>Communication preferences</li>
              <li>User-generated content (reviews, ratings)</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">2. How We Use Your Information</h2>
            <p>We use the collected information for:</p>
            <ul className="list-disc pl-5 space-y-2">
              <li>Providing and improving our services</li>
              <li>Processing your bookings and transactions</li>
              <li>Communicating with you about our services</li>
              <li>Personalizing your experience</li>
              <li>Analytics and service improvement</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">3. Information Sharing and Disclosure</h2>
            <p>We may share your information with:</p>
            <ul className="list-disc pl-5 space-y-2">
              <li>Wineries when you make bookings</li>
              <li>Service providers who assist our operations</li>
              <li>Other users when you post public reviews</li>
              <li>Law enforcement when required by law</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">4. Social Login Data</h2>
            <p>When you log in using social media accounts:</p>
            <ul className="list-disc pl-5 space-y-2">
              <li>We receive basic profile information</li>
              <li>We never post without your permission</li>
              <li>You can revoke access at any time</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">5. Your Data Rights</h2>
            <p>You have the right to:</p>
            <ul className="list-disc pl-5 space-y-2">
              <li>Access your personal data</li>
              <li>Correct inaccurate data</li>
              <li>Request data deletion</li>
              <li>Opt-out of marketing communications</li>
              <li>Export your data</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">6. Data Security</h2>
            <p>We implement appropriate security measures to protect your personal information, including encryption and secure server storage.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">7. Cookies and Tracking</h2>
            <p>We use cookies and similar technologies to:</p>
            <ul className="list-disc pl-5 space-y-2">
              <li>Remember your preferences</li>
              <li>Analyze site usage</li>
              <li>Customize content</li>
              <li>Improve user experience</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">8. Children's Privacy</h2>
            <p>Our service is not intended for users under the age of 18. We do not knowingly collect information from children.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">9. Changes to This Policy</h2>
            <p>We may update this policy periodically. We will notify you of any significant changes via email or through the service.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">10. Contact Us</h2>
            <p>For privacy-related inquiries:</p>
            <p className="mt-2">
              Email: i@drinkwine.co.za<br />
              Address: Klapmuts, South Africa
            </p>
          </section>

          <div className="mt-8 text-sm text-gray-500">
            <p>Last updated: January 18, 2025</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;