import React from 'react';

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white shadow rounded-lg p-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Terms of Service</h1>
        
        <div className="space-y-6 text-gray-600">
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">1. Acceptance of Terms</h2>
            <p>By accessing and using DrinkWine ("the Service"), you accept and agree to be bound by the terms and provisions of this agreement.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">2. Description of Service</h2>
            <p>DrinkWine is a platform that connects wine enthusiasts with wineries, enabling users to discover, book tastings, and explore wine experiences.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">3. User Account and Security</h2>
            <ul className="list-disc pl-5 space-y-2">
              <li>You must provide accurate and complete information when creating an account</li>
              <li>You are responsible for maintaining the security of your account</li>
              <li>You must notify us immediately of any unauthorized access to your account</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">4. User Conduct</h2>
            <p>Users agree not to:</p>
            <ul className="list-disc pl-5 space-y-2">
              <li>Violate any applicable laws or regulations</li>
              <li>Impersonate any person or entity</li>
              <li>Interfere with the proper functioning of the Service</li>
              <li>Engage in any activity that disrupts or diminishes the quality of our service</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">5. Content and Intellectual Property</h2>
            <p>All content provided on DrinkWine is the property of DrinkWine or its licensors and is protected by copyright and other intellectual property laws.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">6. Third-Party Services</h2>
            <p>Our service may integrate with third-party services. Users acknowledge that their use of such services is governed by the respective third-party terms of service.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">7. Limitation of Liability</h2>
            <p>DrinkWine shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of our service.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">8. Modifications to Service</h2>
            <p>We reserve the right to modify or discontinue the Service at any time without notice.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">9. Governing Law</h2>
            <p>These terms shall be governed by and construed in accordance with the laws of South Africa.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">10. Contact Information</h2>
            <p>If you have any questions about these Terms, please contact us at:</p>
            <p className="mt-2">
              Email: i@drinkwine.co.za<br />
              Address: Klapmuts, South Africa
            </p>
          </section>

          <div className="mt-8 text-sm text-gray-500">
            <p>Last updated: January 18, 2025</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;