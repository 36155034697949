// components/WineryPopup.jsx
import React, { useState } from 'react';
import { Card, CardContent } from './ui/card';
import { Clock, Phone, Wine, MapPin, X, Users } from 'lucide-react';
import { getBusynessLevel } from '../utils/busynessUtil';

const BusyIndicator = ({ status, level }) => {
  const colors = {
    0: 'bg-gray-100 text-gray-600',
    1: 'bg-green-100 text-green-700',
    2: 'bg-yellow-100 text-yellow-700',
    3: 'bg-red-100 text-red-700'
  };

  const icons = {
    0: '🔒',
    1: '👤',
    2: '👥',
    3: '👥👥'
  };

  return (
    <div className={`flex items-center gap-2 px-3 py-1 rounded-full ${colors[level]}`}>
      <span>{icons[level]}</span>
      <span className="text-sm capitalize">{status}</span>
    </div>
  );
};

const WineryPopup = ({ winery, onClose }) => {
  const [imageError, setImageError] = useState(false);
  const busyness = getBusynessLevel(winery);

  const openGoogleMaps = () => {
    if (winery.coordinates) {
      const url = `https://www.google.com/maps/search/?api=1&query=${winery.coordinates[1]},${winery.coordinates[0]}`;
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <Card className="bg-white rounded-lg shadow-lg w-[400px] max-w-[90vw] relative overflow-hidden">
      <button
        onClick={onClose}
        className="absolute top-2 right-2 z-50 p-1 bg-white rounded-full shadow-md hover:bg-gray-100 transition-colors"
      >
        <X className="w-5 h-5" />
      </button>

      <div className="relative h-48">
        {winery.img && !imageError ? (
          <img
            src={winery.img}
            alt={winery.name}
            className="w-full h-full object-cover"
            onError={() => setImageError(true)}
          />
        ) : (
          <div className="w-full h-full bg-gray-200 flex items-center justify-center">
            <Wine className="w-12 h-12 text-gray-400" />
          </div>
        )}
        <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />

        <div className="absolute bottom-0 left-0 right-0 p-4">
          <h2 className="text-xl font-bold text-white mb-2">{winery.name}</h2>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-1 text-white/90">
              <MapPin className="w-4 h-4" />
              <p className="text-sm">{winery.address}</p>
            </div>
            <BusyIndicator status={busyness.status} level={busyness.level} />
          </div>
        </div>
      </div>

      <CardContent className="p-4 space-y-4 bg-white">
        {/* Opening Hours */}
        <div className="flex items-start gap-3">
          <Clock className="w-5 h-5 mt-1 flex-shrink-0 text-[#822727]" />
          <div>
            <h4 className="font-medium mb-1">Opening Hours</h4>
            <p className="text-sm text-gray-600">{winery.hours}</p>
          </div>
        </div>

        {/* Current Status */}
        <div className="flex items-start gap-3">
          <Users className="w-5 h-5 mt-1 flex-shrink-0 text-[#822727]" />
          <div>
            <h4 className="font-medium mb-1">Current Status</h4>
            <p className="text-sm text-gray-600">
              {busyness.status === 'closed'
                ? 'Currently closed'
                : `Currently ${busyness.status} - ${getBusyDescription(busyness.level)}`
              }
            </p>
          </div>
        </div>

        {/* Contact */}
        <div className="flex items-start gap-3">
          <Phone className="w-5 h-5 mt-1 flex-shrink-0 text-[#822727]" />
          <div>
            <h4 className="font-medium mb-1">Contact</h4>
            <a
              href={`tel:${winery.phone}`}
              className="text-sm text-gray-600 hover:text-[#822727]"
            >
              {winery.phone}
            </a>
          </div>
        </div>

        {/* Features */}
        <div className="flex items-start gap-3">
          <Wine className="w-5 h-5 mt-1 flex-shrink-0 text-[#822727]" />
          <div>
            <h4 className="font-medium mb-2">Experiences</h4>
            <div className="flex flex-wrap gap-2">
              {winery.features.map((feature, index) => (
                <span
                  key={index}
                  className="text-sm px-3 py-1 bg-gray-100 rounded-full hover:bg-gray-200 transition-colors cursor-default"
                >
                  {feature}
                </span>
              ))}
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex gap-2 pt-2">
          <button
            className="flex-1 bg-[#822727] text-white px-4 py-2 rounded-lg hover:bg-[#722323] transition-colors text-sm font-medium"
            onClick={() => {/* Add booking functionality */ }}
          >
            Book Tasting
          </button>
          <button
            className="flex-1 border border-[#822727] text-[#822727] px-4 py-2 rounded-lg hover:bg-[#822727]/10 transition-colors text-sm font-medium"
            onClick={openGoogleMaps}
          >
            View on Map
          </button>
        </div>
      </CardContent>
    </Card>
  );
};

const getBusyDescription = (level) => {
  switch (level) {
    case 0: return 'Please check opening hours';
    case 1: return 'Perfect time to visit';
    case 2: return 'Expect some wait times';
    case 3: return 'Reservations recommended';
    default: return '';
  }
};

export default WineryPopup;