// components/Features.jsx
import React from 'react';
import { MapPin, Wine, Grape, UtensilsCrossed } from 'lucide-react';

const Features = () => {
  const features = [
    {
      icon: <MapPin className="h-6 w-6 text-[#822727]" />,
      title: 'Interactive Wine Routes',
      description: `Navigate Robertson Valley's premier wine estates with real-time updates on tastings and availability.`
    },
    {
      icon: <Wine className="h-6 w-6 text-[#822727]" />,
      title: 'Personalized Experiences',
      description: 'Get customized recommendations based on your preferences and past visits.'
    },
    {
      icon: <Grape className="h-6 w-6 text-[#822727]" />,
      title: 'Easy Booking',
      description: 'Book tastings and tours directly through the app with instant confirmation.'
    },
    {
      icon: <UtensilsCrossed className="h-6 w-6 text-[#822727]" />,
      title: 'Exclusive Events',
      description: 'Access special wine events and member-only tastings at top estates.'
    }
  ];

  return (
    <div className="py-12 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center w-full">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl text-center w-full">
            Experience Wine Tourism Reimagined
          </h2>
        </div>

        <div className="mt-10">
          <div className="grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-4">
            {features.map((feature, index) => (
              <div key={index} className="flex flex-col items-center text-center">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-white border border-[#822727] text-white">
                  {feature.icon}
                </div>
                <h3 className="mt-4 text-lg font-medium text-gray-900">{feature.title}</h3>
                <p className="mt-2 text-base text-gray-500">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;