import React, { createContext, useState, useContext, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ServiceAPI } from '../services/url';
import { AuthService } from '../services/auth';

const API_BASE_URL = ServiceAPI;

const AuthContext = createContext({
  user: null,
  loading: true,
  role: null
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState(null);

  useEffect(() => {
    console.log('Setting up auth state listener');
    const auth = getAuth();
    
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      console.log('Auth state changed:', firebaseUser);
      
      if (firebaseUser) {
        try {
          // Get user data from your backend
          const response = await fetch(`${API_BASE_URL}/auth/login`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              uid: firebaseUser.uid,
            }),
          });
          const userData = await response.json();
          console.log('User data from backend:', userData);
          
          setUser(firebaseUser);
          setRole(userData.user.role);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        console.log('No user found, clearing state');
        setUser(null);
        setRole(null);
      }
      setLoading(false);
    });

    return () => {
      console.log('Cleaning up auth state listener');
      unsubscribe();
    };
  }, []);

  const logout = async () => {
    try {
      await AuthService.logout();
      setUser(null);
      setRole(null);
    } catch (error) {
      console.error('Logout error:', error);
      // Handle any errors, perhaps show a notification to the user
    }
  };

  const value = {
    user,
    loading,
    logout,
    role,
    setUser, // Adding this to allow manual updates
    setRole  // Adding this to allow manual updates
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);