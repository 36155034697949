import React, { useState, useEffect } from 'react';
import { X, Wine, Grape, Star, MapPin, Clock, ChevronRight, Heart } from 'lucide-react';
import { UserService } from '../services/api';

const FavouritesComponent = ({ onClose }) => {
  const [activeTab, setActiveTab] = useState('wines');
  const [favoriteWines, setFavoriteWines] = useState([]);
  const [favoriteWineries, setFavoriteWineries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getFavs = async () => {
    // Fetch favorite wines and wineries when the component mounts
    const w = await UserService.getFavoriteWines();
    const ww = await UserService.getFavoriteWineries();
    setFavoriteWineries(ww);
    setFavoriteWines(w);
  };

  useEffect(() => {
    getFavs();
  }, []);

  const handleClick = async (wineryId, wineId) => {

    setIsLoading(true);
    try {
      if (wineryId) {
        const response = await UserService.setFavoriteWinery(wineryId, true);
        if (response.message) {
          getFavs();
        }
      } if (wineId) {
        const response = await UserService.setFavoriteWineStatus(wineId, true);
        if (response.message) {
          getFavs();
        }
      }

    } catch (error) {
      console.error('Error toggling favorite:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const WineCard = ({ wine }) => (
    <div className="bg-white rounded-lg border shadow-sm hover:shadow-md transition-shadow p-4">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-4 h-4 border-2 border-gray-300 border-t-[#822727] rounded-full animate-spin" />
        </div>
      )}
      <div className="flex justify-between items-start">
        <div>
          <div className="flex items-center gap-2">
            <Wine className="w-5 h-5 text-[#822727]" />
            <h3 className="font-semibold">{wine.name}</h3>
          </div>
          <p className="text-sm text-gray-600 mt-1">{wine.vintage} · {wine.type}</p>
          <p className="text-sm text-gray-500 mt-1">{wine.winery?.name}, {wine.winery?.region}</p>
          <div className="flex items-center gap-1 mt-2">
            <Star className="w-4 h-4 text-yellow-400 fill-current" />
            <span className="text-sm text-gray-600">
              {wine.reviewStats?.averageRating} ({wine.reviewStats?.totalReviews} reviews)
            </span>
          </div>
        </div>
        <button
          className="p-2 hover:bg-[#f7e8e8] rounded-full transition-colors"
          onClick={() => handleClick(null, wine._id)}
        >
          <Heart className="w-5 h-5 text-[#822727] fill-current" />
        </button>
      </div>
    </div>
  );

  const WineryCard = ({ winery }) => (
    <div className="bg-white rounded-lg border shadow-sm hover:shadow-md transition-shadow p-4">
      <div className="flex justify-between items-start">
        <div>
          <div className="flex items-center gap-2">
            <Grape className="w-5 h-5 text-[#822727]" />
            <h3 className="font-semibold">{winery.name}</h3>
          </div>
          <div className="flex items-center gap-1 mt-1">
            <MapPin className="w-4 h-4 text-gray-400" />
            <p className="text-sm text-gray-600">{winery.region}</p>
          </div>
          <p className="text-sm text-gray-600 mt-2">{winery.description}</p>
          <div className="flex flex-wrap gap-2 mt-3">
            {winery.features.map((feature, index) => (
              <span
                key={index}
                className="text-xs bg-gray-100 text-gray-600 px-2 py-1 rounded-full"
              >
                {feature}
              </span>
            ))}
          </div>
          <div className="flex items-center gap-1 mt-3">
            <Star className="w-4 h-4 text-yellow-400 fill-current" />
            <span className="text-sm text-gray-600">
              {winery.reviewStats.averageRating} ({winery.reviewStats.totalReviews} reviews)
            </span>
          </div>
        </div>
        <button
          className="p-2 hover:bg-[#f7e8e8] rounded-full transition-colors"
          onClick={() => handleClick(winery._id)}
        >
          <Heart className="w-5 h-5 text-[#822727] fill-current" />
        </button>
      </div>
    </div>
  );

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-2xl mx-4 my-8 overflow-hidden">
        <div className="flex items-center justify-between px-6 py-4 border-b">
          <h3 className="text-lg font-semibold">My Favorites</h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="border-b">
          <div className="flex">
            <button
              onClick={() => setActiveTab('wines')}
              className={`px-4 py-2 font-medium text-sm border-b-2 transition-colors ${activeTab === 'wines'
                ? 'border-[#822727] text-[#822727]'
                : 'border-transparent text-gray-500 hover:text-gray-700'
                }`}
            >
              Favorite Wines ({favoriteWines.length})
            </button>
            <button
              onClick={() => setActiveTab('wineries')}
              className={`px-4 py-2 font-medium text-sm border-b-2 transition-colors ${activeTab === 'wineries'
                ? 'border-[#822727] text-[#822727]'
                : 'border-transparent text-gray-500 hover:text-gray-700'
                }`}
            >
              Favorite Wineries ({favoriteWineries.length})
            </button>
          </div>
        </div>

        <div className="p-6 overflow-y-auto max-h-[calc(100vh-200px)]">
          {activeTab === 'wines' ? (
            <div className="space-y-4">
              {favoriteWines.map(wine => (
                <WineCard key={wine._id} wine={wine} />
              ))}
            </div>
          ) : (
            <div className="space-y-4">
              {favoriteWineries.map(winery => (
                <WineryCard key={winery._id} winery={winery} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FavouritesComponent;