import React, { useState, useEffect } from 'react';
import WineForm from './WineForm';
import FavoriteButton from './FavoriteButton';
import { WineService } from '../services/api';
import { Wine, Star } from 'lucide-react';

const AddWineModal = ({ formik, initEdit }) => {
  const [edit, setEdit] = useState(initEdit || !formik.values._id);

  const handleSubmit = async (e) => {
    try {
      const response = await WineService.createWine(formik.values);

      console.log('Wine created:', response);
      setEdit(false);
    } catch (error) {
      console.error('Error creating wine:', error);
    }
  };


  const RatingButtons = ({ value, total }) => (
    <div className="flex gap-1">
      {[1, 2, 3, 4, 5].map((rating) => (
        <button
          key={rating}
          type="button"
          className={`p-1 ${value >= rating ? 'text-yellow-400' : 'text-gray-300'}`}
        >
          <Star className="w-6 h-6 fill-current" />
        </button>
      ))} <p className="text-sm text-gray-600">({total} reviews)</p>
    </div>
  );

  return (
    <div>
      {/* Selected Wine Info */}
      {!edit && <div className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg">
        <Wine className="w-6 h-6 text-[#822727]" />
        <div>
          <h3 className="font-medium">{formik.values.name}</h3>
          <p className="text-sm text-gray-600">{formik.values.winery?.name} {formik.values.vintage}</p>
          <FavoriteButton
            wineId={formik.values._id}
            className="absolute top-2 right-2"
          />
          <RatingButtons value={formik.values.reviewStats?.averageRating} total={formik.values.reviewStats?.totalReviews} />
        </div>
      </div>}

      {edit && (<div className="p-6">

        <WineForm
          formik={formik}
        />

        <div className="flex justify-end gap-3 mt-6">
          <button
            type="button"
            onClick={() => setEdit(!edit)}
            className="px-4 py-2 border rounded-lg hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            type="submit"
            className="px-4 py-2 bg-[#822727] text-white rounded-lg hover:bg-[#722323]"
          >
            Save Wine
          </button>
        </div>
      </div>)}
    </div>
  );
};

export default AddWineModal;