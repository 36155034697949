import React, { useState, useEffect, useCallback } from 'react';
import {
  Star, Camera, Search, Wine,
  MapPin,
  Phone,
  Mail,
  Globe,
  Clock,
  Plus,
  X, Box, Grape, Percent, Beaker
} from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import LabelProcessor from './LabelProcessor';
import { WineService, ReviewService } from '../services/api';
import _ from 'lodash';
import FavoriteButton from './FavoriteButton';
import { useFormik } from 'formik';
import WinerySearch from './WinerySearch';
import Text from './ui/Text';
import Error from './Error';
import WineReview from './WineReview';
import * as Yup from 'yup';


const WineryReviewForm = ({ selectedWinery, onCancel }) => {
  const [showWineForm, setShowWineForm] = useState(false);
  const [error, setError] = useState(false);

  const wineryReview = useFormik({
    initialValues: {
      rating: 5,
      title: '',
      content: '',
      visitDate: new Date().toISOString().split('T')[0],
      wineReviews: []
    }
  });

  const handleSubmitReview = useCallback(async () => {
    try {
      let response = await ReviewService.createWineryReview(selectedWinery._id, wineryReview.values);
    } catch (error) {
      //console.error('Error submitting review:', error);
      setError(error.message);
    }
  }, [wineryReview]);

  if (showWineForm) {
    return (<div className="border rounded-lg p-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="font-semibold">Add Wine Review</h3>
        <button
          onClick={() => setShowWineForm(false)}
          className="text-gray-400 hover:text-gray-600"
        >
          <X className="w-5 h-5" />
        </button>
      </div>
      <WineReview onSubmit={(d) => {
        console.log('submitting wine review...', d);
        wineryReview.setFieldValue("wineReviews", [...wineryReview.values.wineReviews, d]);
        setShowWineForm(false);
      }}
        wineryReview={true}
        onCancel={() => setShowWineForm(false)} />
    </div>);
  }

  return (
    <div className="space-y-4 p-4">
      {/* Main Winery Review */}
      <div className="space-y-4">
        <Error error={error} />
        <div>
          <label className="block text-sm font-medium mb-1">Overall Winery Rating</label>
          <div className="flex gap-1">
            {[1, 2, 3, 4, 5].map((star) => (
              <button
                key={star}
                onClick={() => wineryReview.setFieldValue("rating", star)}
                className={`p-1 ${wineryReview.values.rating >= star ? 'text-yellow-400' : 'text-gray-300'}`}
              >
                <Star className="w-6 h-6 fill-current" />
              </button>
            ))}
          </div>
        </div>
        <Text prop={'title'} label={'Review Title'} formik={wineryReview} />
        <Text prop={'content'} label={'Detail'} multiline placeholder="Share your experience..." formik={wineryReview} />
        <Text prop={'visitDate'} label={'Visit Date'} type="date" formik={wineryReview} />
      </div>

      {/* Wine Reviews List */}
      {wineryReview.values.wineReviews.length > 0 && (
        <div className="border rounded-lg p-4 space-y-4">
          <h3 className="font-semibold">Wine Reviews</h3>
          {wineryReview.values.wineReviews.map((review, index) => (
            <div key={index} className="flex items-start gap-4 py-2 border-b last:border-0">
              <div className="flex-1">
                <div className="flex items-center gap-1">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <Star
                      key={star}
                      className={`w-4 h-4 ${review.rating >= star ? 'text-yellow-400 fill-current' : 'text-gray-300'}`}
                    />
                  ))}
                </div>
                <p className="text-sm text-gray-600 mt-1">{review.wineName}</p>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Add Wine Review Button or Form */}
      {!showWineForm && (
        <button
          onClick={() => setShowWineForm(true)}
          className="flex items-center justify-center gap-2 w-full px-4 py-2 border-2 border-dashed rounded-lg hover:border-[#822727] hover:bg-[#822727]/5"
        >
          <Plus className="w-5 h-5" />
          Add Wine Review
        </button>
      )}

      {/* Submit Button */}
      <button
        onClick={handleSubmitReview}
        className="w-full px-4 py-2 bg-[#822727] text-white rounded-lg hover:bg-[#722323]"
      >
        Submit Review
      </button>
      <button
        onClick={() => {
          console.log('cancel review');
          wineryReview.resetForm();
          onCancel();
        }}
        className="w-full px-4 py-2 bg-[#822727] text-white rounded-lg hover:bg-[#722323]"
      >
        Cancel Review
      </button>
    </div>
  )
}

const TechnicalDetails = ({ details }) => (
  <div className="grid grid-cols-2 md:grid-cols-4 gap-4 bg-gray-50 p-4 rounded-lg">
    <div className="flex flex-col items-center p-3 bg-white rounded-lg shadow-sm">
      <Percent className="w-5 h-5 text-[#822727] mb-2" />
      <span className="text-sm text-gray-500">Alcohol</span>
      <span className="font-semibold">{details?.alcohol || '-'}%</span>
    </div>
    <div className="flex flex-col items-center p-3 bg-white rounded-lg shadow-sm">
      <Beaker className="w-5 h-5 text-[#822727] mb-2" />
      <span className="text-sm text-gray-500">pH</span>
      <span className="font-semibold">{details?.pH || '-'}</span>
    </div>
    <div className="flex flex-col items-center p-3 bg-white rounded-lg shadow-sm">
      <Box className="w-5 h-5 text-[#822727] mb-2" />
      <span className="text-sm text-gray-500">Acidity</span>
      <span className="font-semibold">{details?.acidity || '-'} g/L</span>
    </div>
    <div className="flex flex-col items-center p-3 bg-white rounded-lg shadow-sm">
      <Grape className="w-5 h-5 text-[#822727] mb-2" />
      <span className="text-sm text-gray-500">Sugar</span>
      <span className="font-semibold">{details?.residualSugar || '-'} g/L</span>
    </div>
  </div>
);

const ReviewCard = ({ review }) => (
  <div className="border rounded-lg p-4 mb-4">
    <div className="flex justify-between items-start mb-2">
      <div>
        <div className="flex items-center gap-2">
          <div className="w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center">
            {review.user?.firstName?.charAt(0)}
          </div>
          <div>
            <div className="font-medium">
              {review.user?.firstName} {review.user?.lastName}
            </div>
            <div className="text-sm text-gray-500">
              {new Date(review.createdAt).toLocaleDateString()}
            </div>
          </div>
        </div>
      </div>
      <div className="flex">
        {[...Array(5)].map((_, i) => (
          <Star
            key={i}
            className={`w-4 h-4 ${i < review.rating ? 'text-yellow-400 fill-current' : 'text-gray-300'}`}
          />
        ))}
      </div>
    </div>

    {review.tastingNotes && (
      <div className="mt-4 space-y-3">
        {review.tastingNotes.appearance && (
          <div className="bg-gray-50 p-3 rounded-lg">
            <h4 className="font-medium mb-2">Appearance</h4>
            <div className="text-sm">
              {Object.keys(review.tastingNotes.appearance).map((key) => (
                <div key={key} className="flex items-center gap-2">
                  <span className="font-medium">{key}:</span>
                  <span>{review.tastingNotes.appearance[key]}</span>
                </div>
              ))}
            </div>
          </div>
        )}
        {review.tastingNotes.nose && (
          <div className="bg-gray-50 p-3 rounded-lg">
            <h4 className="font-medium mb-2">Nose</h4>
            <div className="text-sm">
              {review.tastingNotes.nose.notes}
            </div>
          </div>
        )}
        {review.tastingNotes.palate && (
          <div className="bg-gray-50 p-3 rounded-lg">
            <h4 className="font-medium mb-2">Palate</h4>
            <div className="text-sm">
              {review.tastingNotes.palate.notes}
            </div>
          </div>
        )}
      </div>
    )}

    {review.generalNotes && (
      <div className="mt-4">
        <p className="text-gray-700">{review.generalNotes}</p>
      </div>
    )}
  </div>
);

const WineryTab = ({ selectedWinery }) => {
  const { user } = useAuth();
  const [reviewing, setReviewing] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [wineryId, setWineryId] = useState();
  const [activeTab, setActiveTab] = useState('details'); // ['details', 'reviews', 'pairings']

  useEffect(() => {
    if (selectedWinery && selectedWinery._id != wineryId) {
      GetWineryReviews(selectedWinery._id);
      setWineryId(selectedWinery._id);
    }
  }, [selectedWinery]);

  const GetWineryReviews = async (selectedWineId) => {
    const allReviews = await ReviewService.getWineryReviews(selectedWineId);

    console.log(allReviews);
    setReviews(allReviews.reviews);
  };

  const getOpeningHoursDisplay = () => {
    const days = [
      { key: 'monday', label: 'Mon' },
      { key: 'tuesday', label: 'Tue' },
      { key: 'wednesday', label: 'Wed' },
      { key: 'thursday', label: 'Thu' },
      { key: 'friday', label: 'Fri' },
      { key: 'saturday', label: 'Sat' },
      { key: 'sunday', label: 'Sun' }
    ];

    return days.map(({ key, label }) => {
      const hours = selectedWinery.operatingHours[key];
      return {
        day: label,
        hours: hours.isOpen ? `${hours.open} - ${hours.close}` : 'Closed'
      };
    });
  };

  return (<div className='overflow-y-auto max-h-[calc(100vh-200px)]' style={{ paddingBottom: '120px' }}>

    {!reviewing && <div className="border-b">
      <div className="flex">
        <button
          onClick={() => setActiveTab('details')}
          className={`px-4 py-2 font-medium text-sm ${activeTab === 'details'
            ? 'border-b-2 border-[#822727] text-[#822727]'
            : 'text-gray-500 hover:text-gray-700'
            }`}
        >
          Details
        </button>
        <button
          onClick={() => setActiveTab('reviews')}
          className={`px-4 py-2 font-medium text-sm ${activeTab === 'reviews'
            ? 'border-b-2 border-[#822727] text-[#822727]'
            : 'text-gray-500 hover:text-gray-700'
            }`}
        >
          Reviews ({selectedWinery?.reviewStats?.totalReviews || 0})
        </button>
      </div>
    </div>}
    {reviewing && <WineryReviewForm selectedWinery={selectedWinery} onCancel={() => setReviewing(false)} />}
    {!reviewing && <div className='p-6'>
      {activeTab === 'details' && (
        <div className="space-y-6">



          {/* Content Grid */}
          <div className="grid md:grid-cols-2 gap-6 p-6">
            {/* Contact Information */}
            <div className="space-y-4">
              <h2 className="font-semibold text-gray-900">Contact Information</h2>
              <div className="space-y-3">
                <a
                  href={`tel:${selectedWinery.contact.phone}`}
                  className="flex items-center gap-2 text-gray-600 hover:text-[#822727]"
                >
                  <Phone className="w-4 h-4" />
                  <span>{selectedWinery.contact.phone}</span>
                </a>
                <a
                  href={`mailto:${selectedWinery.contact.email}`}
                  className="flex items-center gap-2 text-gray-600 hover:text-[#822727]"
                >
                  <Mail className="w-4 h-4" />
                  <span>{selectedWinery.contact.email}</span>
                </a>
                <a
                  href={selectedWinery.contact.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 text-gray-600 hover:text-[#822727]"
                >
                  <Globe className="w-4 h-4" />
                  <span>Visit Website</span>
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${selectedWinery.location.coordinates[1]},${selectedWinery.location.coordinates[0]}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 text-gray-600 hover:text-[#822727]"
                >
                  <MapPin className="w-4 h-4" />
                  <span>{selectedWinery.location.address}</span>
                </a>
              </div>
            </div>

            {/* Opening Hours */}
            <div className="space-y-4">
              <h2 className="font-semibold text-gray-900">Opening Hours</h2>
              <div className="space-y-2">
                {getOpeningHoursDisplay().map(({ day, hours }) => (
                  <div key={day} className="flex items-center justify-between text-sm">
                    <span className="text-gray-600">{day}</span>
                    <span className={`font-medium ${hours === 'Closed' ? 'text-red-600' : 'text-gray-900'}`}>
                      {hours}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Description */}
          <div className="px-6 pb-6">
            <p className="text-gray-600">{selectedWinery.description}</p>
          </div>
        </div>
      )}

      {activeTab === 'reviews' && (
        <div>
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-lg font-medium">Reviews</h3>
            {user && (
              <button
                onClick={() => setReviewing(true)}
                className="px-4 py-2 bg-[#822727] text-white rounded-lg hover:bg-[#722323]"
              >
                Write a Review
              </button>
            )}
          </div>
          {reviews.length > 0 ? (
            reviews.map((review) => (
              <ReviewCard key={review._id} review={review} />
            ))
          ) : (
            <div className="text-center py-8 text-gray-500">
              No reviews yet. Be the first to review this wine!
            </div>
          )}
        </div>
      )}
    </div>}
  </div>);
}

const WineTab = ({ selectedWine }) => {
  const { user } = useAuth();
  const [reviews, setReviews] = useState([]);
  const [wineId, setWineId] = useState();
  const [activeTab, setActiveTab] = useState('details'); // ['details', 'reviews', 'pairings']

  useEffect(() => {
    if (selectedWine && selectedWine._id != wineId) {
      GetReviews(selectedWine._id);
      setWineId(selectedWine._id);
    }
  }, [selectedWine]);

  const GetReviews = async (selectedWineId) => {
    const allReviews = await ReviewService.getWineReviews(selectedWineId);

    console.log(allReviews);
    setReviews(allReviews.reviews);
  };

  return (<div className='overflow-y-auto max-h-[calc(100vh-200px)]' style={{ paddingBottom: '120px' }}>

    <div className="border-b">
      <div className="flex">
        <button
          onClick={() => setActiveTab('details')}
          className={`px-4 py-2 font-medium text-sm ${activeTab === 'details'
            ? 'border-b-2 border-[#822727] text-[#822727]'
            : 'text-gray-500 hover:text-gray-700'
            }`}
        >
          Details
        </button>
        <button
          onClick={() => setActiveTab('reviews')}
          className={`px-4 py-2 font-medium text-sm ${activeTab === 'reviews'
            ? 'border-b-2 border-[#822727] text-[#822727]'
            : 'text-gray-500 hover:text-gray-700'
            }`}
        >
          Reviews ({selectedWine?.reviewStats?.totalReviews || 0})
        </button>
        {false && <button
          onClick={() => setActiveTab('pairings')}
          className={`px-4 py-2 font-medium text-sm ${activeTab === 'pairings'
            ? 'border-b-2 border-[#822727] text-[#822727]'
            : 'text-gray-500 hover:text-gray-700'
            }`}
        >
          Food Pairings
        </button>}
      </div>
    </div>

    <div className="p-6">
      {activeTab === 'details' && (
        <div className="space-y-6">
          {selectedWine?.description && <div>
            <h3 className="text-lg font-medium mb-4">About this Wine</h3>
            <p className="text-gray-700">{selectedWine?.description}</p>
          </div>}

          <div>
            <h3 className="text-lg font-medium mb-4">Technical Details</h3>
            <TechnicalDetails details={selectedWine?.technicalDetails} />
          </div>

          {selectedWine?.awards?.length > 0 && (
            <div>
              <h3 className="text-lg font-medium mb-4">Awards & Recognition</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {selectedWine.awards.map((award, index) => (
                  <div key={index} className="bg-gray-50 p-4 rounded-lg">
                    <div className="font-medium">{award.name}</div>
                    <div className="text-sm text-gray-500">{award.year} - {award.organization}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      {activeTab === 'reviews' && (
        <div>
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-lg font-medium">Reviews</h3>
            {user && (
              <button className="px-4 py-2 bg-[#822727] text-white rounded-lg hover:bg-[#722323]">
                Write a Review
              </button>
            )}
          </div>
          {reviews.length > 0 ? (
            reviews.map((review) => (
              <ReviewCard key={review._id} review={review} />
            ))
          ) : (
            <div className="text-center py-8 text-gray-500">
              No reviews yet. Be the first to review this wine!
            </div>
          )}
        </div>
      )}

      {activeTab === 'pairings' && (
        <div>
          <h3 className="text-lg font-medium mb-4">Recommended Food Pairings</h3>
          {selectedWine?.foodPairings?.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {selectedWine.foodPairings.map((pairing, index) => (
                <div key={index} className="bg-gray-50 p-4 rounded-lg">
                  <div className="font-medium">{pairing.dish}</div>
                  <div className="text-sm text-gray-600 mt-1">{pairing.description}</div>
                  <div className="text-sm text-gray-500 mt-2">{pairing.category}</div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-8 text-gray-500">
              No food pairings available for this wine.
            </div>
          )}
        </div>
      )}
    </div>
  </div>);
}

const WineDetails = ({ onClose }) => {
  const [selectedWine, setSelectedWine] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectionMethod, setSelectionMethod] = useState(null);
  const [selectedWinery, setSelectedWinery] = useState(null);

  const formik = useFormik({
    initialValues: {
      name: '',
      type: '',
      vintage: new Date().getFullYear(),
      varietal: '',
      price: '',
      description: '',
      wineryId: '',
      technicalDetails: {
        alcohol: '',
        pH: '',
        acidity: '',
        residualSugar: ''
      },
      frontLabel: null,
      backLabel: null
    }
  });

  useEffect(() => {
    console.log(formik.values.name);
    if (!selectedWine && formik.values.name) {
      setSelectedWine({
        ...formik.values
      });
    }
  }, [formik]);

  const WinerySearchForm = () => {
    const [showWineForm, setShowWineForm] = useState(false);
    const [error, setError] = useState(false);

    const wineryReview = useFormik({
      initialValues: {
        rating: 5,
        title: '',
        content: '',
        visitDate: new Date().toISOString().split('T')[0],
        wineReviews: []
      }
    });

    return (
      <div className="space-y-4">
        <WinerySearch
          selectedWinery={selectedWinery}
          onWinerySelect={(winery) => {
            setSelectedWinery(winery);
          }}
        />
      </div>);
  }

  const WineSearch = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);

    const searchWines = async (searchQuery) => {
      if (!searchQuery.trim()) return;

      setLoading(true);
      try {
        const response = await WineService.getWines({ search: searchQuery });
        setResults(response.wines);
      } catch (error) {
        console.error('Error searching wines:', error);
      } finally {
        setLoading(false);
      }
    };

    const handleSearch = _.debounce(searchWines, 300);

    return (
      <div className="space-y-4">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="text"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
              handleSearch(e.target.value);
            }}
            placeholder="Search wines by name, winery, or variety..."
            className="w-full pl-10 pr-4 py-2 border rounded-lg"
          />
        </div>

        {loading ? (
          <div className="text-center py-4">
            <div className="animate-spin w-6 h-6 border-2 border-[#822727] border-t-transparent rounded-full mx-auto"></div>
          </div>
        ) : (
          <div className="max-h-64 overflow-y-auto space-y-2">
            {results.map((wine) => (
              <button
                key={wine._id}
                onClick={() => { formik.setValues({ ...wine }); }
                }
                className="w-full text-left p-3 hover:bg-gray-50 rounded-lg transition-colors border"
              >
                <div className="font-medium">{wine.name}</div>
                <div className="text-sm text-gray-600">
                  {wine.winery.name} · {wine.vintage}
                </div>
              </button>
            ))}
          </div>
        )}
      </div>
    );
  };

  const WineHeader = () => (

    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      {/* Hero Section */}
      <div className="relative h-40 bg-gradient-to-r from-[#822727] to-[#9c4b4b]">
        <div className="flex items-center justify-between px-6 py-4">
          <h3 className="text-lg font-semibold"></h3>
          <button
            onClick={() => {
              setSelectionMethod(null);
              setSelectedWinery(null);
              setSelectedWine(null);
              formik.resetForm();
            }}
            className="text-white hover:text-gray-700"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="absolute bottom-0 left-0 right-0 p-6 text-white">
          <div className="flex items-center gap-3 mb-2">
            <Wine className="w-8 h-8" />
            <div>
              <h1 className="text-2xl font-bold">{selectedWine?.name}</h1>
            </div>
          </div>
        </div>
      </div>

      {/* Quick Info Bar */}
      <div className="border-b">
        <div className="flex items-center px-6 py-3 gap-6 text-sm overflow-x-auto">
          <div
            onClick={() => {
              setSelectedWinery(selectedWine?.winery);
              setSelectionMethod(null);
              setSelectedWine(null);
              formik.resetForm();
            }}
            className="flex items-center gap-1 text-red-600">
            <Grape className="w-4 h-4" />
            {selectedWine?.winery?.name}
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center">
              <div className="flex">
                {[...Array(5)].map((_, i) => (
                  <Star
                    key={i}
                    className={`w-4 h-4 ${i < (selectedWine?.reviewStats?.averageRating || 0)
                      ? 'text-yellow-400 fill-current'
                      : 'text-gray-300'
                      }`}
                  />
                ))}
              </div>
              <span className="ml-2 text-sm text-gray-500">
                ({selectedWine?.reviewStats?.totalReviews || 0} reviews)
              </span>
            </div>
            <FavoriteButton wineId={selectedWine?._id} />
          </div>
        </div>
      </div>


    </div>
  );

  const WineryHeader = ({ winery }) => {
    const isCurrentlyOpen = () => {
      const now = new Date();
      const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
      const today = days[now.getDay()];
      const currentTime = now.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });

      const todayHours = selectedWinery.operatingHours[today];
      return todayHours.isOpen && currentTime >= todayHours.open && currentTime <= todayHours.close;
    };

    return (
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        {/* Hero Section */}
        <div className="relative h-40 bg-gradient-to-r from-[#822727] to-[#9c4b4b]">

          <div className="flex items-center justify-between px-6 py-4">
            <h3 className="text-lg font-semibold"></h3>
            <button
              onClick={() => {
                setSelectionMethod(null);
                setSelectedWinery(null);
                setSelectedWine(null);
                formik.resetForm();
              }}
              className="text-white hover:text-gray-700"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          <div className="absolute bottom-0 left-0 right-0 p-6 text-white">
            <div className="flex items-center gap-3 mb-2">
              <Grape className="w-8 h-8" />
              <div>
                <h1 className="text-2xl font-bold">{winery.name}</h1>
                <div className="flex items-center gap-2 text-sm">
                  <MapPin className="w-4 h-4" />
                  <span>{winery.region}</span>
                  {winery.isVerified && (
                    <span className="bg-blue-500/20 text-white px-2 py-0.5 rounded-full text-xs">
                      Verified
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

        </div>


        {/* Quick Info Bar */}
        <div className="border-b">
          <div className="flex items-center px-6 py-3 gap-6 text-sm overflow-x-auto">
            <div className={`flex items-center gap-1 ${isCurrentlyOpen() ? 'text-green-600' : 'text-red-600'}`}>
              <Clock className="w-4 h-4" />
              <span>{isCurrentlyOpen() ? 'Open Now' : 'Closed'}</span>
            </div>
            <div className="flex items-center gap-1">
              {[...Array(5)].map((_, i) => (
                <Star
                  key={i}
                  className={`w-4 h-4 ${i < (selectedWinery?.reviewStats?.averageRating || 0)
                    ? 'text-yellow-400 fill-current'
                    : 'text-gray-300'
                    }`}
                />
              ))}
              <span>
                {selectedWinery.reviewStats.totalReviews === 0
                  ? 'No reviews yet'
                  : `${selectedWinery.reviewStats.averageRating.toFixed(1)} (${selectedWinery.reviewStats.totalReviews} reviews)`}
              </span>
            </div>
            <FavoriteButton wineryId={selectedWinery?._id} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-2xl mx-4 my-8 overflow-hidden">
        {!selectedWine && !selectedWinery && (
          <div className="p-6">

            {!selectionMethod && (
              <div className="grid grid-rows-2 gap-4">
                <button
                  onClick={() => setSelectionMethod('scan')}
                  className="flex flex-col items-center justify-center p-6 border-2 border-dashed rounded-lg hover:border-[#822727] hover:bg-[#822727]/5 transition-colors"
                >
                  <Camera className="w-8 h-8 mb-2 text-[#822727]" />
                  <span className="text-sm">Scan Label</span>
                </button>
                <button
                  onClick={() => setSelectionMethod('search')}
                  className="flex flex-col items-center justify-center p-6 border-2 border-dashed rounded-lg hover:border-[#822727] hover:bg-[#822727]/5 transition-colors"
                >
                  <Wine className="w-8 h-8 mb-2 text-[#822727]" />
                  <span className="text-sm">Search Wine</span>
                </button>
                <button
                  onClick={() => setSelectionMethod('searchWinery')}
                  className="flex flex-col items-center justify-center p-6 border-2 border-dashed rounded-lg hover:border-[#822727] hover:bg-[#822727]/5 transition-colors"
                >
                  <Grape className="w-8 h-8 mb-2 text-[#822727]" />
                  <span className="text-sm">Search Winery</span>
                </button>
              </div>
            )}

            {selectionMethod && (
              <div className="mt-6">
                <div className="flex justify-between items-center mb-4">
                  <h4 className="font-medium">
                    {selectionMethod === 'scan' && 'Scan Wine Label'}
                    {selectionMethod === 'search' && 'Search for Wine'}
                    {selectionMethod === 'searchWinery' && 'Search for Winery'}
                  </h4>
                  <button
                    onClick={() => setSelectionMethod(null)}
                    className="text-sm text-[#822727] hover:text-[#722323]"
                  >
                    Change Search
                  </button>
                </div>
                {selectionMethod === 'scan' && <LabelProcessor setSelectedWine={setSelectedWine} />}
                {selectionMethod === 'search' && <WineSearch />}
                {selectionMethod === 'searchWinery' && <WinerySearchForm />}
              </div>
            )}
          </div>
        )}
        {selectedWine && (
          <>
            <WineHeader />
            <WineTab selectedWine={selectedWine} />
          </>
        )}
        {selectedWinery && (
          <>
            <WineryHeader winery={selectedWinery} />
            <WineryTab selectedWinery={selectedWinery} />
          </>
        )}
      </div>
    </div>
  );
};

export default WineDetails;