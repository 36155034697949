import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const ImageCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);

  const slides = [
    {
      image: require("../images/3.jpeg"),
      title: "Premium Wine Tastings",
      description: "Experience curated tastings at Robertson Valley's finest estates",
      overlayPosition: "left"
    },
    {
        image: require("../images/4.jpeg"),
      title: "Scenic Vineyard Tours",
      description: "Discover the beauty of our world-class vineyards",
      overlayPosition: "center"
    },
    {
        image: require("../images/1.jpeg"),
      title: "Exclusive Cellar Tours",
      description: "Go behind the scenes with expert winemakers",
      overlayPosition: "right"
    },
    {
        image: require("../images/2.jpeg"),
      title: "Gourmet Experiences",
      description: "Pair fine wines with exceptional cuisine",
      overlayPosition: "center"
    }
  ];

  useEffect(() => {
    if (!isAutoPlaying) return;

    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000);

    return () => clearInterval(timer);
  }, [isAutoPlaying, slides.length]);

  const goToSlide = (index) => {
    setCurrentSlide(index);
    setIsAutoPlaying(false);
    setTimeout(() => setIsAutoPlaying(true), 10000);
  };

  const goToPrevious = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
    setIsAutoPlaying(false);
    setTimeout(() => setIsAutoPlaying(true), 10000);
  };

  const goToNext = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
    setIsAutoPlaying(false);
    setTimeout(() => setIsAutoPlaying(true), 10000);
  };

  return (
    <div className="relative w-full max-w-7xl mx-auto h-[600px] overflow-hidden rounded-xl">
      {/* Main Carousel */}
      <div 
        className="flex transition-transform duration-500 ease-out h-full"
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {slides.map((slide, index) => (
          <div key={index} className="w-full h-full flex-shrink-0 relative">
            <img
              src={slide.image}
              alt={slide.title}
              className="w-full h-full object-cover"
            />
            <div 
              className={`absolute inset-0 flex items-center ${
                slide.overlayPosition === 'left' ? 'justify-start' :
                slide.overlayPosition === 'right' ? 'justify-end' :
                'justify-center'
              }`}
            >
              <div className="bg-black bg-opacity-50 text-white p-8 mx-8 rounded-lg max-w-lg transform transition-all duration-500 ease-out">
                <h2 className="text-3xl font-bold mb-4">{slide.title}</h2>
                <p className="text-lg">{slide.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Navigation Arrows */}
      <button
        onClick={goToPrevious}
        className="absolute left-4 top-1/2 -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-75 rounded-full p-2 transition-all duration-200 group"
      >
        <ChevronLeft className="w-6 h-6 text-black group-hover:scale-110 transition-transform" />
      </button>
      <button
        onClick={goToNext}
        className="absolute right-4 top-1/2 -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-75 rounded-full p-2 transition-all duration-200 group"
      >
        <ChevronRight className="w-6 h-6 text-black group-hover:scale-110 transition-transform" />
      </button>

      {/* Dots Navigation */}
      <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`w-3 h-3 rounded-full transition-all duration-300 ${
              currentSlide === index 
                ? 'bg-white w-8' 
                : 'bg-white/50 hover:bg-white/75'
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageCarousel;