// components/WaitlistSection.jsx
import React, { useState } from 'react';

const WaitlistSection = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add your email collection logic here
    setStatus('Thanks for joining the waitlist!');
    setEmail('');
  };

  return (
    <div className="bg-white py-16 sm:py-24">
      <div className="relative sm:py-16">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="relative rounded-2xl px-6 py-10 bg-[#822727] overflow-hidden shadow-xl sm:px-12 sm:py-20">
            <div className="relative">
              <div className="sm:text-center">
                <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                  Get Early Access
                </h2>
                <p className="mt-6 mx-auto max-w-2xl text-lg text-white">
                  Join our waitlist to be the first to experience the future of wine tourism in Robertson Valley.
                </p>
              </div>
              <form onSubmit={handleSubmit} className="mt-12 sm:mx-auto sm:max-w-lg">
                <div className="sm:flex">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="block w-full px-5 py-3 text-base text-gray-900 placeholder-gray-500 border border-transparent rounded-md shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-[#822727]"
                    placeholder="Enter your email"
                  />
                  <button
                    type="submit"
                    className="mt-3 sm:mt-0 sm:ml-3 block w-full sm:w-auto px-5 py-3 text-base font-medium text-[#822727] bg-white rounded-md shadow hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-[#822727]"
                  >
                    Join Waitlist
                  </button>
                </div>
                {status && (
                  <p className="mt-3 text-sm text-white text-center">{status}</p>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaitlistSection;