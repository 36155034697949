import React, { useState, useEffect } from 'react';
import { Star, Camera, Search, Wine, ChevronDown, ChevronUp } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import LabelProcessor from './LabelProcessor';
import WineProfile from './WineProfile';
import Error from './Error';
import { WineService, ReviewService } from '../services/api';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({});

const WineReview = ({ onSubmit, onCancel = () => { }, wineryReview = false }) => {
  const { user } = useAuth();
  const [selectedWine, setSelectedWine] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectionMethod, setSelectionMethod] = useState(null); // 'scan' or 'search'
  const [reviewData, setReviewData] = useState({
    rating: 5,
    tastingNotes: {
      appearance: {
        clarity: '',
        intensity: '',
        color: '',
        notes: ''
      },
      nose: {
        intensity: '',
        development: '',
        aromas: [],
        notes: ''
      },
      palate: {
        sweetness: '',
        acidity: '',
        tannin: '',
        alcohol: '',
        body: '',
        flavor: '',
        finish: '',
        notes: ''
      }
    },
    generalNotes: '',
    visitDate: new Date().toISOString().split('T')[0],
    public: true
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      type: '',
      vintage: new Date().getFullYear(),
      varietal: '',
      price: '',
      description: '',
      wineryId: '',
      technicalDetails: {
        alcohol: '',
        pH: '',
        acidity: '',
        residualSugar: ''
      },
      frontLabel: null,
      backLabel: null
    },
    validationSchema
  });

  useEffect(() => {
    if (!selectedWine && formik.values.name) {
      setSelectedWine({
        ...formik.values
      });
    }
  }, [formik]);

  useEffect(() => {
    if (selectedWine && selectedWine._id && reviewData.wineId !== selectedWine._id) {
      setReviewData({
        ...reviewData,
        wineId: selectedWine._id,
        wineName: selectedWine.name
      });
    }
  }, [selectedWine]);

  const [expandedSections, setExpandedSections] = useState({
    appearance: false,
    nose: false,
    palate: false
  });

  const handleRatingChange = (rating) => {
    setReviewData(prev => ({
      ...prev,
      rating
    }));
  };

  const handleTastingNoteChange = (category, subcategory, value) => {
    setReviewData(prev => ({
      ...prev,
      tastingNotes: {
        ...prev.tastingNotes,
        [category]: {
          ...prev.tastingNotes[category],
          [subcategory]: value
        }
      }
    }));
  };

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedWine) return;

    try {
      setLoading(true);
      const response = await ReviewService.createWineReview(selectedWine._id, reviewData);

      //setError('');
      if (onSubmit) onSubmit(response);
    } catch (error) {
      console.error('Error submitting review:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const WineSearch = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);

    const searchWines = async (searchQuery) => {
      if (!searchQuery.trim()) return;

      setLoading(true);
      try {
        const response = await WineService.getWines({ search: searchQuery });
        setResults(response.wines);
      } catch (error) {
        console.error('Error searching wines:', error);
      } finally {
        setLoading(false);
      }
    };

    const handleSearch = _.debounce(searchWines, 300);

    return (
      <div className="space-y-4">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="text"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
              handleSearch(e.target.value);
            }}
            placeholder="Search wines by name, winery, or variety..."
            className="w-full pl-10 pr-4 py-2 border rounded-lg"
          />
        </div>

        {loading ? (
          <div className="text-center py-4">
            <div className="animate-spin w-6 h-6 border-2 border-[#822727] border-t-transparent rounded-full mx-auto"></div>
          </div>
        ) : (
          <div className="max-h-64 overflow-y-auto space-y-2">
            {results.map((wine) => (
              <button
                key={wine._id}
                onClick={() => { formik.setValues({ ...wine }); }
                }
                className="w-full text-left p-3 hover:bg-gray-50 rounded-lg transition-colors border"
              >
                <div className="font-medium">{wine.name}</div>
                <div className="text-sm text-gray-600">
                  {wine.winery.name} · {wine.vintage}
                </div>
              </button>
            ))}
          </div>
        )}
      </div>
    );
  };

  const SelectWineMethod = () => (
    <div className="space-y-6">

      {!selectionMethod ? (
        <div className="grid grid-cols-2 gap-4">
          <button
            onClick={() => setSelectionMethod('scan')}
            className="flex flex-col items-center justify-center p-6 border-2 border-dashed rounded-lg hover:border-[#822727] hover:bg-[#822727]/5 transition-colors"
          >
            <Camera className="w-8 h-8 mb-2 text-[#822727]" />
            <span className="text-sm">Scan Label</span>
          </button>
          <button
            onClick={() => setSelectionMethod('search')}
            className="flex flex-col items-center justify-center p-6 border-2 border-dashed rounded-lg hover:border-[#822727] hover:bg-[#822727]/5 transition-colors"
          >
            <Search className="w-8 h-8 mb-2 text-[#822727]" />
            <span className="text-sm">Search Wine</span>
          </button>
        </div>
      ) : (
        <div>
          <div className="flex justify-between items-center mb-4">
            <h4 className="font-medium">
              {selectionMethod === 'scan' ? 'Scan Wine Label' : 'Search for Wine'}
            </h4>
            <button
              onClick={() => setSelectionMethod(null)}
              className="text-sm text-[#822727] hover:text-[#722323]"
            >
              Change Method
            </button>
          </div>

          {selectionMethod === 'scan' ? <LabelProcessor formik={formik} /> : <WineSearch />}
        </div>
      )}
    </div>
  );

  const ReviewSection = ({ title, section, children }) => (
    <div className="border rounded-lg mb-4">
      <button
        onClick={() => toggleSection(section)}
        className="w-full flex items-center justify-between p-4 hover:bg-gray-50"
      >
        <span className="font-medium">{title}</span>
        {expandedSections[section] ?
          <ChevronUp className="w-5 h-5" /> :
          <ChevronDown className="w-5 h-5" />
        }
      </button>
      {expandedSections[section] && (
        <div className="p-4 border-t bg-gray-50">
          {children}
        </div>
      )}
    </div>
  );

  const ratingOptions = {
    appearance: {
      clarity: ['hazy', 'clear', 'brilliant'],
      intensity: ['pale', 'medium', 'deep'],
      color: ['purple', 'ruby', 'garnet', 'tawny', 'gold', 'amber']
    },
    nose: {
      intensity: ['light', 'medium-', 'medium', 'medium+', 'pronounced'],
      development: ['youthful', 'developing', 'developed']
    },
    palate: {
      sweetness: ['dry', 'off-dry', 'medium', 'sweet'],
      acidity: ['low', 'medium-', 'medium', 'medium+', 'high'],
      tannin: ['low', 'medium-', 'medium', 'medium+', 'high'],
      alcohol: ['low', 'medium', 'high'],
      body: ['light', 'medium-', 'medium', 'medium+', 'full'],
      finish: ['short', 'medium', 'long']
    }
  };

  const RatingButtons = ({ value, onChange }) => (
    <div className="flex gap-1">
      {[1, 2, 3, 4, 5].map((rating) => (
        <button
          key={rating}
          type="button"
          onClick={() => onChange(rating)}
          className={`p-1 ${value >= rating ? 'text-yellow-400' : 'text-gray-300'}`}
        >
          <Star className="w-6 h-6 fill-current" />
        </button>
      ))}
    </div>
  );

  if (!selectedWine) {
    return <SelectWineMethod />;
  }

  return (
    <div className="space-y-6">
      <WineProfile formik={formik} />
      <Error error={error} />
      {selectedWine._id && <div>
        {/* Overall Rating */}
        <div className="space-y-2">
          <label className="block font-medium">Overall Rating</label>
          <RatingButtons
            value={reviewData.rating}
            onChange={handleRatingChange}
          />
        </div>

        {/* Tasting Notes Sections */}
        <ReviewSection title="Appearance" section="appearance">
          <div className="space-y-4">
            {Object.entries(ratingOptions.appearance).map(([key, options]) => (
              <div key={key}>
                <label className="block text-sm font-medium mb-1 capitalize">{key}</label>
                <div className="flex flex-wrap gap-2">
                  {options.map((option) => (
                    <button
                      key={option}
                      type="button"
                      onClick={() => handleTastingNoteChange('appearance', key, option)}
                      className={`px-3 py-1 rounded-full text-sm ${reviewData.tastingNotes.appearance[key] === option
                        ? 'bg-[#822727] text-white'
                        : 'bg-gray-100 hover:bg-gray-200'
                        }`}
                    >
                      {option}
                    </button>
                  ))}
                </div>
              </div>
            ))}
            <div>
              <label className="block text-sm font-medium mb-1">Additional Notes</label>
              <textarea
                value={reviewData.tastingNotes.appearance.notes}
                onChange={(e) => handleTastingNoteChange('appearance', 'notes', e.target.value)}
                className="w-full px-3 py-2 border rounded-lg"
                rows={2}
              />
            </div>
          </div>
        </ReviewSection>

        <ReviewSection title="Nose (Aroma)" section="nose">
          <div className="space-y-4">
            {Object.entries(ratingOptions.nose).map(([key, options]) => (
              <div key={key}>
                <label className="block text-sm font-medium mb-1 capitalize">{key}</label>
                <div className="flex flex-wrap gap-2">
                  {options.map((option) => (
                    <button
                      key={option}
                      type="button"
                      onClick={() => handleTastingNoteChange('nose', key, option)}
                      className={`px-3 py-1 rounded-full text-sm ${reviewData.tastingNotes.nose[key] === option
                        ? 'bg-[#822727] text-white'
                        : 'bg-gray-100 hover:bg-gray-200'
                        }`}
                    >
                      {option}
                    </button>
                  ))}
                </div>
              </div>
            ))}
            <div>
              <label className="block text-sm font-medium mb-1">Aroma Notes</label>
              <textarea
                value={reviewData.tastingNotes.nose.notes}
                onChange={(e) => handleTastingNoteChange('nose', 'notes', e.target.value)}
                className="w-full px-3 py-2 border rounded-lg"
                rows={2}
                placeholder="Describe the aromas..."
              />
            </div>
          </div>
        </ReviewSection>

        <ReviewSection title="Palate" section="palate">
          <div className="space-y-4">
            {Object.entries(ratingOptions.palate).map(([key, options]) => (
              <div key={key}>
                <label className="block text-sm font-medium mb-1 capitalize">{key}</label>
                <div className="flex flex-wrap gap-2">
                  {options.map((option) => (
                    <button
                      key={option}
                      type="button"
                      onClick={() => handleTastingNoteChange('palate', key, option)}
                      className={`px-3 py-1 rounded-full text-sm ${reviewData.tastingNotes.palate[key] === option
                        ? 'bg-[#822727] text-white'
                        : 'bg-gray-100 hover:bg-gray-200'
                        }`}
                    >
                      {option}
                    </button>
                  ))}
                </div>
              </div>
            ))}
            <div>
              <label className="block text-sm font-medium mb-1">Flavor Notes</label>
              <textarea
                value={reviewData.tastingNotes.palate.notes}
                onChange={(e) => handleTastingNoteChange('palate', 'notes', e.target.value)}
                className="w-full px-3 py-2 border rounded-lg"
                rows={2}
                placeholder="Describe the flavors..."
              />
            </div>
          </div>
        </ReviewSection>

        {/* General Notes */}
        <div>
          <label className="block font-medium mb-2">Overall Impressions</label>
          <textarea
            value={reviewData.generalNotes}
            onChange={(e) => setReviewData(prev => ({ ...prev, generalNotes: e.target.value }))}
            className="w-full px-3 py-2 border rounded-lg"
            rows={4}
            placeholder="Share your overall thoughts about this wine..."
          />
        </div>

        {/* Submit Buttons */}<div className="flex gap-3">
          <button
            type="button"
            onClick={onCancel}
            disabled={loading}
            className={`flex-1 px-4 py-2 border rounded-lg transition-colors
      ${loading
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                : 'hover:bg-gray-50'
              }`}
          >
            Cancel
          </button>
          <button
            onClick={wineryReview ? onSubmit.bind(this, reviewData) : handleSubmit}
            disabled={loading}
            className={`flex-1 px-4 py-2 rounded-lg transition-colors
      flex items-center justify-center gap-2
      ${loading
                ? 'bg-[#822727]/50 cursor-not-allowed'
                : 'bg-[#822727] hover:bg-[#722323]'
              } text-white`}
          >
            {loading && (
              <svg className="animate-spin h-4 w-4" viewBox="0 0 24 24">
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            )}
            {loading ? 'Submitting...' : 'Submit Review'}
          </button>
        </div>
      </div>}
    </div>
  );
};

export default WineReview;