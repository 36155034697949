import React from 'react';

const Favorites = () => {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Favorites</h1>
      {/* Favorites content */}
    </div>
  );
};

export default Favorites;
