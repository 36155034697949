
export function register() {
    if ('serviceWorker' in navigator) {
      const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
  
      window.addEventListener('load', () => {
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
  
        registerValidSW(swUrl);
      });
    }
  }
  
  function registerValidSW(swUrl) {
    navigator.serviceWorker
      .register(swUrl)
      .then((registration) => {
        // Check for updates every hour
        setInterval(() => {
          registration.update();
        }, 1000 * 60 * 60);
  
        registration.addEventListener('updatefound', () => {
          const installingWorker = registration.installing;
          
          if (installingWorker == null) return;
  
          installingWorker.addEventListener('statechange', () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                // New content is available
                dispatchUpdateEvent();
              }
            }
          });
        });
      })
      .catch((error) => {
        console.error('Error during service worker registration:', error);
      });
  }
  
  function dispatchUpdateEvent() {
    const event = new CustomEvent('swUpdateAvailable');
    window.dispatchEvent(event);
  }
  
  export function unregister() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then((registration) => {
          registration.unregister();
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  }