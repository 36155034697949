// Updated App.jsx with animations
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { ProtectedRoute } from './components/ProtectedRoute';
import RoleBasedNavigation from './components/navigation/RoleBasedNavigation';

import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Features from './components/Features';
import ImageCarousel from './components/ImageCarousel';
import InteractiveMap from './components/InteractiveMap';
import WaitlistSection from './components/WaitlistSection';
import Stats from './components/Stats';
import Footer from './components/Footer';
import ScrollRevealSection from './components/ScrollRevealSection';
import 'mapbox-gl/dist/mapbox-gl.css';
import LoginForm from './components/auth/LoginForm';
import ResetForm from './components/auth/ResetForm';
import RegisterForm from './components/auth/RegisterForm';

import WineryManagement from './components/admin/WineryManagement';
import { WineryAdminDashboard } from './layouts/WineryAdminDashboard';
import { WineryStaffDashboard } from './layouts/WineryStaffDashboard';

import AdminSettings from './components/admin/AdminSettings';
import AdminOverview from './components/admin/AdminOverview';
import UserManagement from './components/admin/UserManagement';
import Wines from './components/admin/WineManagement';

import InteractiveDashboard from './components/dashboard/InteractiveDashboard';
import Wineries from './components/dashboard/Wineries';
import MyBookings from './components/dashboard/MyBookings';
import Favorites from './components/dashboard/Favorites';
import Profile from './components/dashboard/Profile';
import ReviewsManagement from './components/dashboard/ReviewsManagement';

import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import DataDeletion from './components/DataDeletion';

import './config/firebase'; // Import this at the top level

function App() {
  const [updateAvailable, setUpdateAvailable] = useState(false);

  useEffect(() => {
    const handleUpdateAvailable = () => {
      setUpdateAvailable(true);
    };

    window.addEventListener('swUpdateAvailable', handleUpdateAvailable);

    return () => {
      window.removeEventListener('swUpdateAvailable', handleUpdateAvailable);
    };
  }, []);

  const handleUpdate = () => {
    // Send message to service worker to skip waiting
    navigator.serviceWorker.controller?.postMessage('skipWaiting');

    // Reload the page to activate the new service worker
    window.location.reload();
  };

  return (
    <AuthProvider>
      {updateAvailable && (
        <div className="fixed bottom-4 right-4 bg-[#822727] text-white p-4 rounded-lg shadow-lg">
          <p>A new version is available!</p>
          <button
            onClick={handleUpdate}
            className="mt-2 px-4 py-2 bg-white text-[#822727] rounded hover:bg-gray-100"
          >
            Update Now
          </button>
        </div>
      )}
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginForm />} />
          <Route path="/reset-password" element={<ResetForm />} />
          <Route path="/register" element={<RegisterForm />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/data-deletion" element={<DataDeletion />} />

          {/* User routes */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute roles={['user']}>
                <RoleBasedNavigation title={'Dashboard'} />
              </ProtectedRoute>
            }
          >
            <Route path="" element={<InteractiveDashboard />} />
            <Route path="wineries" element={<Wineries />} />
            <Route path="bookings" element={<MyBookings />} />
            <Route path="favorites" element={<Favorites />} />
            <Route path="reviews" element={<ReviewsManagement />} />
            <Route path="profile" element={<Profile />} />
          </Route>

          {/* Winery Staff routes */}
          <Route
            path="/staff-dashboard"
            element={
              <ProtectedRoute roles={['winery_staff']}>
                <WineryStaffDashboard />
              </ProtectedRoute>
            }
          />

          {/* Winery Admin routes */}
          <Route
            path="/winery-dashboard"
            element={
              <ProtectedRoute roles={['winery_admin']}>
                <WineryAdminDashboard />
              </ProtectedRoute>
            }
          />

          {/* Admin routes */}
          <Route path="/admin" element={
            <ProtectedRoute roles={['admin']}>
              <RoleBasedNavigation title={'Admin Dashboard'} />
            </ProtectedRoute>
          }>
            <Route index element={<AdminOverview />} />
            <Route path="wineries" element={<WineryManagement />} />
            <Route path="users" element={<UserManagement />} />
            <Route path="settings" element={<AdminSettings />} />
            <Route path="wines" element={<Wines />} />
          </Route>

          <Route path="/" element={<div className="min-h-screen bg-white">
            <Navbar />
            <main>
              <Hero />
              <ScrollRevealSection>
                <ImageCarousel />
              </ScrollRevealSection>
              <ScrollRevealSection>
                <Features />
              </ScrollRevealSection>
              <ScrollRevealSection>
                <InteractiveMap />
              </ScrollRevealSection>
              <ScrollRevealSection>
                <Stats />
              </ScrollRevealSection>
              <ScrollRevealSection>
                <WaitlistSection />
              </ScrollRevealSection>
            </main>
            <Footer />
          </div>} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
