import React, { useState, useEffect } from 'react';
import { Search, MapPin } from 'lucide-react';
import debounce from 'lodash/debounce';
import { WineryService } from '../services/api';

const WinerySearch = ({ onWinerySelect, selectedWinery = null }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [wineries, setWineries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [locationError, setLocationError] = useState('');

  // Initialize search term if winery is selected
  useEffect(() => {
    if (selectedWinery) {
      setSearchTerm(selectedWinery.name);
    }
  }, [selectedWinery]);

  // Get user location and fetch nearby wineries on component mount
  useEffect(() => {
    const getUserLocation = () => {
      if ("geolocation" in navigator) {
        console.log('userLocatino');
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            console.log('userLocatino',latitude, longitude);
            setUserLocation({ latitude, longitude });
            
            try {
              setLoading(true);
              const response = await WineryService.getNearbyWineries(latitude, longitude);
              if (response.wineries) {
                setWineries(response.wineries);
                setShowResults(true);
              }
            } catch (error) {
              console.error('Error fetching nearby wineries:', error);
              setLocationError('Failed to fetch nearby wineries');
            } finally {
              setLoading(false);
            }
          },
          (error) => {
            console.error('Error getting location:', error);
            setLocationError('Unable to get your location');
          }
        );
      } else {
        setLocationError('Geolocation is not supported by your browser');
      }
    };

    console.log('userLocatino');
    getUserLocation();
  }, []);

  // Debounced search function
  const debouncedSearch = debounce(async (term) => {
    if (!term || term.length < 2) {
      // If search is cleared, show nearby wineries again
      if (userLocation) {
        try {
          setLoading(true);
          const response = await WineryService.getNearbyWineries(
            userLocation.latitude,
            userLocation.longitude
          );
          setWineries(response.wineries);
        } catch (error) {
          console.error('Error fetching nearby wineries:', error);
        } finally {
          setLoading(false);
        }
      }
      return;
    }

    setLoading(true);
    try {
      const response = await WineryService.searchWineries(term);
      setWineries(response.wineries);
    } catch (error) {
      console.error('Error searching wineries:', error);
    } finally {
      setLoading(false);
    }
  }, 300);

  // Handle input change
  const handleInputChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    debouncedSearch(term);
    setShowResults(true);
  };

  // Handle winery selection
  const handleWinerySelect = (winery) => {
    setSearchTerm(winery?.name);
    setShowResults(false);
    onWinerySelect(winery);
  };

  const formatDistance = (distance) => {
    if (distance < 1) {
      return `${Math.round(distance * 1000)}m`;
    }
    return `${distance.toFixed(1)}km`;
  };

  return (
    <div className="relative">
      <div className="relative">
        <input
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          onFocus={() => setShowResults(true)}
          placeholder="Search for a winery..."
          className="w-full px-3 py-2 pl-10 border rounded-lg focus:ring-[#822727] focus:border-[#822727]"
        />
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
      </div>

      {locationError && (
        <div className="mt-2 text-sm text-red-600">
          {locationError}
        </div>
      )}

      {showResults && (
        <div className="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-48 overflow-y-auto">
          {loading ? (
            <div className="p-4 text-center text-gray-500">
              Loading...
            </div>
          ) : wineries.length === 0 ? (
            <div className="p-4 text-center text-gray-500">
              No wineries found
            </div>
          ) : (
            wineries.map((winery) => (
              <button
                key={winery._id}
                type="button"
                onClick={() => handleWinerySelect(winery)}
                className="w-full px-4 py-2 text-left hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
              >
                <div className="font-medium">{winery.name}</div>
                <div className="text-sm text-gray-500 flex items-center gap-1">
                  <MapPin className="w-3 h-3" />
                  <span>{winery.location?.address}</span>
                  {winery.distance && (
                    <span className="ml-2 text-xs bg-gray-100 px-2 py-0.5 rounded-full">
                      {formatDistance(winery.distance)}
                    </span>
                  )}
                </div>
              </button>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default WinerySearch;