
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider
} from 'firebase/auth';
import { googleProvider, facebookProvider } from '../config/firebase';
import { ServiceAPI } from './url';

const API_BASE_URL = ServiceAPI;

export const AuthService = {
  // Register new user
  register: async (userData) => {
    try {
      const auth = getAuth();
      const { email, password, ...otherData } = userData;

      // Create Firebase user
      const { user } = await createUserWithEmailAndPassword(auth, email, password);

      // Register user in your backend
      const response = await fetch(`${API_BASE_URL}/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uid: user.uid,
          email,
          ...otherData
        }),
      });

      if (!response.ok) throw new Error('Registration failed');

      return await response.json();
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  },

  // Login user
  login: async (email, password) => {
    try {
      console.log('Starting login process');
      const auth = getAuth();

      // Login with Firebase
      const { user: firebaseUser } = await signInWithEmailAndPassword(auth, email, password);
      console.log('Firebase login successful:', firebaseUser);

      // Get user data from your backend
      const response = await fetch(`${API_BASE_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uid: firebaseUser.uid,
        }),
      });

      if (!response.ok) throw new Error('Backend login failed');

      const userData = await response.json();
      console.log('Backend login successful:', userData);

      return userData;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  },

  // Logout user
  logout: async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
    } catch (error) {
      console.error('Logout error:', error);
      throw error;
    }
  },

  handleSocialLogin: async (provider) => {
    try {
      const auth = getAuth();
      const result = await signInWithPopup(auth, provider);
      const { user } = result;

      // Get additional user info for name
      const { given_name, family_name } = provider.providerId === 'google.com'
        ? result._tokenResponse
        : result.additionalUserInfo.profile;

      // Register/Login with backend
      const response = await fetch(`${API_BASE_URL}/auth/social-login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uid: user.uid,
          email: user.email,
          firstName: given_name || user.displayName?.split(' ')[0] || '',
          lastName: family_name || user.displayName?.split(' ').slice(1).join(' ') || '',
          provider: provider.providerId
        }),
      });

      if (!response.ok) throw new Error('Social login failed');
      return await response.json();
    } catch (error) {
      console.error('Social login error:', error);
      throw error;
    }
  },

  // Google Login
  loginWithGoogle: async () => {
    return AuthService.handleSocialLogin(googleProvider);
  },

  // Facebook Login
  loginWithFacebook: async () => {
    return AuthService.handleSocialLogin(facebookProvider);
  },

  // Reset password
  resetPassword: async (email) => {
    try {
      const response = await fetch(`${API_BASE_URL}/auth/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to process password reset request');
      }

      return await response.json();
    } catch (error) {
      console.error('Password reset error:', error);
      throw error;
    }
  }
};