import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCg2cXxrU_mUL5HW5N9HVtbXuIYYScHK8U",
    authDomain: "drinkwine-d7409.firebaseapp.com",
    projectId: "drinkwine-d7409",
    storageBucket: "drinkwine-d7409.firebasestorage.app",
    messagingSenderId: "776870251781",
    appId: "1:776870251781:web:d5d9011b0905bf66b6bb6c",
    measurementId: "G-DMYLBFPXXH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();

export default app;