import React from 'react';

const MyBookings = () => {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">My Bookings</h1>
      {/* Bookings content */}
    </div>
  );
};

export default MyBookings;
