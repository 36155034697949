import React, { useState } from 'react';
import { Upload, Wine, X } from 'lucide-react';
import { WineService } from '../services/api';

const LabelProcessor = ({ formik }) => {
  const [labels, setLabels] = useState({
    frontLabel: null,
    backLabel: null
  });
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const [labelPreviews, setLabelPreviews] = useState({
    frontLabel: null,
    backLabel: null
  });

  const handleImageUpload = (event, type) => {
    const file = event.target.files[0];
    if (file) {
      // Validate file type
      if (!file.type.startsWith('image/')) {
        setError('Please upload an image file');
        return;
      }

      // Create preview URL
      const previewUrl = URL.createObjectURL(file);
      setLabelPreviews(prev => ({
        ...prev,
        [type]: previewUrl
      }));

      console.log(file);
      setLabels(prev => ({
        ...prev,
        [type]: file
      }));
      setError('');
    }
  };

  const removeImage = (type) => {
    // Clean up preview URL
    if (labelPreviews[type]) {
      URL.revokeObjectURL(labelPreviews[type]);
    }

    setLabelPreviews(prev => ({
      ...prev,
      [type]: null
    }));

    setLabels(prev => ({
      ...prev,
      [type]: null
    }));
  };

  const processLabels = async () => {
    if (!labels.frontLabel || !labels.backLabel) {
      setError('Both front and back labels are required');
      return;
    }

    setProcessing(true);
    setError('');

    try {
      const result = await WineService.processLabels(
        labels.frontLabel,
        labels.backLabel
      );

      console.log('Processed labels:', result);
      // Update parent form with processed data
      formik.setValues({
        ...result.wineInfo,
        wineryId: result.wineryInfo?._id,
        new: !result.wineInfo._id
      });

    } catch (error) {
      console.error('Error processing labels:', error);
      setError(error.message || 'Failed to process wine labels');
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        {/* Front Label Upload */}
        <div className="border rounded-lg p-4">
          <div className="flex justify-between items-start mb-2">
            <label className="block text-sm font-medium text-gray-700">
              Front Label
            </label>
            {labelPreviews.frontLabel && (
              <button
                onClick={() => removeImage('frontLabel')}
                className="text-gray-400 hover:text-gray-600"
              >
                <X className="w-4 h-4" />
              </button>
            )}
          </div>
          
          {labelPreviews.frontLabel ? (
            <div className="relative h-40">
              <img
                src={labelPreviews.frontLabel}
                alt="Front Label Preview"
                className="w-full h-full object-contain rounded"
              />
            </div>
          ) : (
            <div className="flex items-center justify-center h-40 bg-gray-50 rounded border-2 border-dashed">
              <input
                type="file"
                onChange={(e) => handleImageUpload(e, 'frontLabel')}
                accept="image/*"
                className="hidden"
                id="frontLabel"
              />
              <label
                htmlFor="frontLabel"
                className="flex flex-col items-center cursor-pointer"
              >
                <Upload className="w-8 h-8 text-gray-400 mb-2" />
                <span className="text-sm text-gray-500">Upload Front Label</span>
              </label>
            </div>
          )}
        </div>

        {/* Back Label Upload */}
        <div className="border rounded-lg p-4">
          <div className="flex justify-between items-start mb-2">
            <label className="block text-sm font-medium text-gray-700">
              Back Label
            </label>
            {labelPreviews.backLabel && (
              <button
                onClick={() => removeImage('backLabel')}
                className="text-gray-400 hover:text-gray-600"
              >
                <X className="w-4 h-4" />
              </button>
            )}
          </div>
          
          {labelPreviews.backLabel ? (
            <div className="relative h-40">
              <img
                src={labelPreviews.backLabel}
                alt="Back Label Preview"
                className="w-full h-full object-contain rounded"
              />
            </div>
          ) : (
            <div className="flex items-center justify-center h-40 bg-gray-50 rounded border-2 border-dashed">
              <input
                type="file"
                onChange={(e) => handleImageUpload(e, 'backLabel')}
                accept="image/*"
                className="hidden"
                id="backLabel"
              />
              <label
                htmlFor="backLabel"
                className="flex flex-col items-center cursor-pointer"
              >
                <Upload className="w-8 h-8 text-gray-400 mb-2" />
                <span className="text-sm text-gray-500">Upload Back Label</span>
              </label>
            </div>
          )}
        </div>
      </div>

      {/* Process Button */}
      <div className="flex flex-col items-center gap-3">
        <button
          type="button"
          onClick={processLabels}
          disabled={!labels.frontLabel || !labels.backLabel || processing}
          className={`px-6 py-2 rounded-lg flex items-center gap-2 ${
            (!labels.frontLabel || !labels.backLabel || processing)
              ? 'bg-gray-300 cursor-not-allowed'
              : 'bg-[#822727] text-white hover:bg-[#722323]'
          }`}
        >
          {processing ? (
            <>
              <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
              Processing Labels...
            </>
          ) : (
            <>
              <Wine className="w-4 h-4" />
              Scan Labels
            </>
          )}
        </button>

        {error && (
          <div className="text-sm text-red-600 text-center">
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default LabelProcessor;