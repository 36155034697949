// utils/busynessUtil.js
export const getBusynessLevel = (winery) => {
    if (!winery || !winery.hours) {
      return { status: 'unknown', level: 0 };
    }
  
    const now = new Date();
    const hour = now.getHours();
    const day = now.getDay(); // 0 is Sunday, 6 is Saturday
    
    // Check if currently open based on hours string
    const isOpen = checkIfOpen(winery.hours, day);
    if (!isOpen) return { status: 'closed', level: 0 };
  
    // Peak hours for different days
    const peakHours = {
      weekday: [11, 12, 13, 14, 15], // 11am - 3pm on weekdays
      weekend: [10, 11, 12, 13, 14, 15, 16] // 10am - 4pm on weekends
    };
  
    // Determine busyness level
    const isWeekend = day === 0 || day === 6;
    const isPeakHour = isWeekend 
      ? peakHours.weekend.includes(hour)
      : peakHours.weekday.includes(hour);
  
    if (isPeakHour) {
      return isWeekend 
        ? { status: 'very busy', level: 3 }
        : { status: 'busy', level: 2 };
    }
  
    // Early morning or late afternoon
    if (hour < 10 || hour > 16) {
      return { status: 'quiet', level: 1 };
    }
  
    return { status: 'moderate', level: 2 };
  };
  
  const checkIfOpen = (hoursString, currentDay) => {
    try {
      // Basic implementation - can be enhanced with more precise hour parsing
      const isWeekend = currentDay === 0 || currentDay === 6;
      const now = new Date();
      const hour = now.getHours();
  
      // Most wineries are open between 9am and 5pm
      if (hour < 9 || hour >= 17) return false;
  
      // If hours string includes "By Appointment", consider it potentially open
      if (hoursString.toLowerCase().includes("by appointment")) {
        return true;
      }
  
      // Check if closed on specific days
      const hoursLower = hoursString.toLowerCase();
      if (isWeekend && !hoursLower.includes("sat") && !hoursLower.includes("sun")) {
        return false;
      }
  
      return true;
    } catch (error) {
      console.error('Error checking opening hours:', error);
      return false;
    }
  };