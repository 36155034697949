import React, { useState, useEffect } from 'react';
import { Wine, Users, Calendar, TrendingUp, Loader } from 'lucide-react';
import { Card, CardContent } from '../ui/card';
import { AdminService } from '../../services/api';

const StatCard = ({ title, value, trend, icon, loading }) => (
  <Card>
    <CardContent className="p-6">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-[#822727]/10 rounded-lg">
            {icon}
          </div>
          <span className="text-gray-600">{title}</span>
        </div>
        <span className={`text-sm ${trend?.startsWith('+') ? 'text-green-600' : 'text-red-600'}`}>
          {trend}
        </span>
      </div>
      {loading ? (
        <div className="h-8 flex items-center">
          <Loader className="w-5 h-5 animate-spin text-gray-400" />
        </div>
      ) : (
        <div className="text-2xl font-bold">{value}</div>
      )}
    </CardContent>
  </Card>
);

const AdminDashboard = () => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [recentActivity, setRecentActivity] = useState([]);
  const [systemStatus, setSystemStatus] = useState({});

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        const response = await AdminService.getStats();
        
        if (!response.recentActivity) throw new Error('Failed to fetch dashboard data');
        
        setStats(response);
        setRecentActivity(response.recentActivity || []);
        setSystemStatus(response.systemStatus || {});
      } catch (err) {
        setError(err.message);
        console.error('Error fetching dashboard data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  if (error) {
    return (
      <div className="p-6">
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded">
          Error loading dashboard: {error}
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 space-y-6">
      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <StatCard
          title="Total Wineries"
          value={stats?.totalWineries?.toLocaleString() || '0'}
          trend={stats?.wineriesGrowth || '+0%'}
          icon={<Wine className="w-5 h-5 text-[#822727]" />}
          loading={loading}
        />
        <StatCard
          title="Total Users"
          value={stats?.totalUsers?.toLocaleString() || '0'}
          trend={stats?.usersGrowth || '+0%'}
          icon={<Users className="w-5 h-5 text-[#822727]" />}
          loading={loading}
        />
        <StatCard
          title="Total Bookings"
          value={stats?.totalBookings?.toLocaleString() || '0'}
          trend={stats?.bookingsGrowth || '+0%'}
          icon={<Calendar className="w-5 h-5 text-[#822727]" />}
          loading={loading}
        />
        <StatCard
          title="Revenue"
          value={`R${stats?.revenue?.toLocaleString() || '0'}`}
          trend={stats?.revenueGrowth || '+0%'}
          icon={<TrendingUp className="w-5 h-5 text-[#822727]" />}
          loading={loading}
        />
      </div>

      {/* Recent Activity */}
      <Card>
        <CardContent className="p-6">
          <h3 className="text-lg font-semibold mb-4">Recent Activity</h3>
          {loading ? (
            <div className="flex justify-center py-4">
              <Loader className="w-6 h-6 animate-spin text-gray-400" />
            </div>
          ) : recentActivity.length > 0 ? (
            <div className="space-y-4">
              {recentActivity.map((activity, index) => (
                <div key={index} className="flex items-start gap-4 pb-4 border-b last:border-0">
                  <div className={`p-2 rounded-full ${
                    activity.type === 'registration' ? 'bg-green-100 text-green-600' :
                    activity.type === 'booking' ? 'bg-blue-100 text-blue-600' :
                    'bg-gray-100 text-gray-600'
                  }`}>
                    {activity.type === 'registration' ? <Users className="w-4 h-4" /> :
                     activity.type === 'booking' ? <Calendar className="w-4 h-4" /> :
                     <Wine className="w-4 h-4" />}
                  </div>
                  <div className="flex-1">
                    <p className="text-sm text-gray-600">{activity.description}</p>
                    <p className="text-xs text-gray-400 mt-1">
                      {new Date(activity.timestamp).toLocaleString()}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-500 text-center py-4">No recent activity</p>
          )}
        </CardContent>
      </Card>

      {/* System Status & Reports */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card>
          <CardContent className="p-6">
            <h3 className="text-lg font-semibold mb-4">System Status</h3>
            {loading ? (
              <div className="flex justify-center py-4">
                <Loader className="w-6 h-6 animate-spin text-gray-400" />
              </div>
            ) : (
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Server Status</span>
                  <span className={`px-2 py-1 rounded-full text-sm ${
                    systemStatus.serverStatus === 'operational' ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'
                  }`}>
                    {systemStatus.serverStatus || 'Unknown'}
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Database Status</span>
                  <span className={`px-2 py-1 rounded-full text-sm ${
                    systemStatus.dbStatus === 'operational' ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'
                  }`}>
                    {systemStatus.dbStatus || 'Unknown'}
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">API Response Time</span>
                  <span className="text-sm text-gray-600">
                    {systemStatus.apiResponseTime || 'N/A'} ms
                  </span>
                </div>
              </div>
            )}
          </CardContent>
        </Card>

        <Card>
          <CardContent className="p-6">
            <h3 className="text-lg font-semibold mb-4">Recent Reports</h3>
            {loading ? (
              <div className="flex justify-center py-4">
                <Loader className="w-6 h-6 animate-spin text-gray-400" />
              </div>
            ) : (
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Pending Reviews</span>
                  <span className="font-semibold">{stats?.pendingReviews || 0}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Support Tickets</span>
                  <span className="font-semibold">{stats?.openTickets || 0}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">System Alerts</span>
                  <span className="font-semibold">{stats?.systemAlerts || 0}</span>
                </div>
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default AdminDashboard;