import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { Link, useLocation, Outlet } from 'react-router-dom';
import {
  Home, Wine, Calendar, Heart, Settings, Users, BarChart,
  LogOut, User, ClipboardList, Star, Menu, X
} from 'lucide-react';

const menuItems = {
  user: [
    { icon: <Wine size={20} />, label: 'Wineries', path: '/dashboard/wineries' },
    { icon: <Calendar size={20} />, label: 'My Bookings', path: '/dashboard/bookings' },
    { icon: <Star size={20} />, label: 'My Reviews', path: '/dashboard/reviews' },
    { icon: <Heart size={20} />, label: 'Favorites', path: '/dashboard/favorites' },
    { icon: <User size={20} />, label: 'Profile', path: '/dashboard/profile' },
  ],
  winery_staff: [
    { icon: <Home size={20} />, label: 'Dashboard', path: '/staff-dashboard' },
    { icon: <Calendar size={20} />, label: 'Bookings', path: '/bookings' },
    { icon: <ClipboardList size={20} />, label: 'Tasks', path: '/tasks' },
    { icon: <User size={20} />, label: 'Profile', path: '/profile' },
  ],
  winery_admin: [
    { icon: <Home size={20} />, label: 'Dashboard', path: '/winery-dashboard' },
    { icon: <Wine size={20} />, label: 'Wines', path: '/wines' },
    { icon: <Calendar size={20} />, label: 'Bookings', path: '/bookings' },
    { icon: <BarChart size={20} />, label: 'Analytics', path: '/analytics' },
    { icon: <Users size={20} />, label: 'Staff', path: '/staff' },
    { icon: <Settings size={20} />, label: 'Settings', path: '/settings' },
  ],
  admin: [
    { icon: <Home size={20} />, label: 'Dashboard', path: '/admin' },
    { icon: <Wine size={20} />, label: 'Wines', path: '/admin/wines' },
    { icon: <Wine size={20} />, label: 'Wineries', path: '/admin/wineries' },
    { icon: <Users size={20} />, label: 'Users', path: '/admin/users' },
    { icon: <Settings size={20} />, label: 'Settings', path: '/admin/settings' }
  ]
};

const RoleBasedNavigation = ({ title }) => {
  const { user, role, logout } = useAuth();
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const userMenu = menuItems[role] || menuItems.user;

  if (role === 'user')
    return (<main className="flex-1">
      <Outlet />
    </main>);

  return (
    <div className="flex min-h-screen bg-gray-50">
      {/* Hamburger button for mobile */}
      <button
        onClick={() => setIsCollapsed(!isCollapsed)}
        className="lg:hidden fixed top-4 left-4 z-50 p-2 rounded-lg bg-white shadow-lg"
      >
        {isCollapsed ? <Menu size={24} /> : <X size={24} />}
      </button>

      {/* Sidebar */}
      <aside className={`fixed lg:static lg:translate-x-0 z-40 h-screen transition-all duration-300 ${
        isCollapsed ? '-translate-x-full lg:translate-x-0' : 'translate-x-0'
      } ${
        isCollapsed ? 'lg:w-20' : 'w-64'
      } bg-white shadow-lg`}>
        <div className="p-6 border-b flex justify-between items-center">
          <h1 className={`text-xl font-bold text-[#822727] truncate ${
            isCollapsed ? 'lg:hidden' : ''
          }`}>{title}</h1>
          {/* Desktop collapse button */}
          <button
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="hidden lg:block text-gray-500 hover:text-gray-700"
          >
            {isCollapsed ? <Menu size={20} /> : <X size={20} />}
          </button>
        </div>
        
        <nav className="p-4">
          <ul className="space-y-2">
            {userMenu.map((item) => (
              <li key={item.path}>
                <Link
                  to={item.path}
                  className={`flex items-center gap-3 ${
                    isCollapsed ? 'lg:justify-center' : ''
                  } p-3 rounded-lg transition-colors ${
                    location.pathname === item.path
                      ? 'bg-[#822727] text-white'
                      : 'text-gray-700 hover:bg-gray-100'
                  }`}
                  title={isCollapsed ? item.label : ''}
                >
                  {item.icon}
                  <span className={isCollapsed ? 'lg:hidden' : ''}>
                    {item.label}
                  </span>
                </Link>
              </li>
            ))}

            <li>
              <button
                onClick={logout}
                className={`flex items-center gap-3 ${
                  isCollapsed ? 'lg:justify-center' : ''
                } p-3 rounded-lg hover:bg-gray-100 text-gray-700 w-full`}
                title={isCollapsed ? 'Logout' : ''}
              >
                <LogOut size={20} />
                <span className={isCollapsed ? 'lg:hidden' : ''}>
                  Logout
                </span>
              </button>
            </li>
          </ul>
        </nav>
      </aside>

      {/* Overlay for mobile */}
      {!isCollapsed && (
        <div 
          className="lg:hidden fixed inset-0 bg-black bg-opacity-50 z-30"
          onClick={() => setIsCollapsed(true)}
        />
      )}

      {/* Main Content */}
      <main className={`flex-1 transition-all duration-300`}>
        <Outlet />
      </main>
    </div>
  );
};

export default RoleBasedNavigation;