import React, { useState, useEffect } from 'react';
import { Users, Wine, Calendar, TrendingUp } from 'lucide-react';

const AdminSettings = () => {
    const [settings, setSettings] = useState({
      siteName: 'DrinkWine',
      supportEmail: 'support@drinkwine.com',
      defaultRegion: 'Robertson',
      bookingLeadTime: '24',
      notifications: {
        email: true,
        push: true,
        sms: false
      },
      maintenance: false
    });
  
    const handleSave = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/settings`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(settings)
        });
        
        if (!response.ok) throw new Error('Failed to save settings');
        
        // Show success message
      } catch (error) {
        console.error('Error saving settings:', error);
        // Show error message
      }
    };
  
    return (
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">System Settings</h2>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-[#822727] text-white rounded-lg hover:bg-[#722323]"
          >
            Save Changes
          </button>
        </div>
  
        <div className="space-y-6">
          {/* General Settings */}
          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-medium mb-4">General Settings</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1">Site Name</label>
                <input
                  type="text"
                  value={settings.siteName}
                  onChange={(e) => setSettings(prev => ({...prev, siteName: e.target.value}))}
                  className="w-full px-3 py-2 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Support Email</label>
                <input
                  type="email"
                  value={settings.supportEmail}
                  onChange={(e) => setSettings(prev => ({...prev, supportEmail: e.target.value}))}
                  className="w-full px-3 py-2 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Default Region</label>
                <select
                  value={settings.defaultRegion}
                  onChange={(e) => setSettings(prev => ({...prev, defaultRegion: e.target.value}))}
                  className="w-full px-3 py-2 border rounded-lg"
                >
                  <option value="Robertson">Robertson</option>
                  <option value="Stellenbosch">Stellenbosch</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Booking Lead Time (hours)</label>
                <input
                  type="number"
                  value={settings.bookingLeadTime}
                  onChange={(e) => setSettings(prev => ({...prev, bookingLeadTime: e.target.value}))}
                  className="w-full px-3 py-2 border rounded-lg"
                />
              </div>
            </div>
          </div>
  
          {/* Notification Settings */}
          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-medium mb-4">Notifications</h3>
            <div className="space-y-3">
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={settings.notifications.email}
                  onChange={(e) => setSettings(prev => ({
                    ...prev,
                    notifications: {...prev.notifications, email: e.target.checked}
                  }))}
                  className="rounded text-[#822727]"
                />
                <span>Email Notifications</span>
              </label>
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={settings.notifications.push}
                  onChange={(e) => setSettings(prev => ({
                    ...prev,
                    notifications: {...prev.notifications, push: e.target.checked}
                  }))}
                  className="rounded text-[#822727]"
                />
                <span>Push Notifications</span>
              </label>
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={settings.notifications.sms}
                  onChange={(e) => setSettings(prev => ({
                    ...prev,
                    notifications: {...prev.notifications, sms: e.target.checked}
                  }))}
                  className="rounded text-[#822727]"
                />
                <span>SMS Notifications</span>
              </label>
            </div>
          </div>
  
          {/* Maintenance Mode */}
          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-medium mb-4">Maintenance</h3>
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={settings.maintenance}
                onChange={(e) => setSettings(prev => ({...prev, maintenance: e.target.checked}))}
                className="rounded text-[#822727]"
              />
              <span>Enable Maintenance Mode</span>
            </label>
            <p className="text-sm text-gray-600 mt-2">
              When enabled, only administrators can access the site.
            </p>
          </div>
        </div>
      </div>
    );
  };
  
  export default AdminSettings;