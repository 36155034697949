import React, { useState, useEffect } from 'react';
import { Heart } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { UserService } from '../services/api';

const FavoriteButton = ({ wineId, wineryId, className = '', onFavoriteChange = () => { }, fav = false }) => {
    const [isFavorite, setIsFavorite] = useState(fav);
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useAuth();

    useEffect(() => {
        const checkFavoriteStatus = async () => {
            if (!user) return;
            try {
                if (wineryId) {
                    const response = await UserService.checkFavoriteWineryStatus(wineryId);
                    setIsFavorite(response.isFavorite);
                } else if (wineId) {
                    const response = await UserService.checkFavoriteWineStatus(wineId);
                    setIsFavorite(response.isFavorite);
                }
            } catch (error) {
                console.error('Error checking favorite status:', error);
            }
        };

        checkFavoriteStatus();
    }, [wineId, user]);

    const handleClick = async (e) => {
        e.preventDefault(); // Prevent event bubbling
        e.stopPropagation();

        setIsLoading(true);
        try {
            if (wineryId) {
                const response = await UserService.setFavoriteWinery(wineryId, isFavorite);
                if (response.message) {
                    const newFavoriteStatus = !isFavorite;
                    setIsFavorite(newFavoriteStatus);
                    onFavoriteChange(newFavoriteStatus);
                }
            } if (wineId) {
                const response = await UserService.setFavoriteWineStatus(wineId, isFavorite);
                if (response.message) {
                    const newFavoriteStatus = !isFavorite;
                    setIsFavorite(newFavoriteStatus);
                    onFavoriteChange(newFavoriteStatus);
                }
            }

        } catch (error) {
            console.error('Error toggling favorite:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <button
            onClick={handleClick}
            disabled={isLoading}
            className={`relative inline-flex items-center justify-center rounded-full transition-all duration-200 
        ${isFavorite ? 'text-red-500 bg-red-50' : 'text-gray-400 bg-white hover:bg-gray-50'} 
        ${isLoading ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
        ${className}`}
            title={isFavorite ? 'Remove from favorites' : 'Add to favorites'}
        >
            <Heart
                className={`w-5 h-5 transition-all duration-200 ${isFavorite ? 'fill-current scale-110' : 'scale-100'}`}
            />

            {/* Loading spinner */}
            {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center">
                    <div className="w-4 h-4 border-2 border-gray-300 border-t-[#822727] rounded-full animate-spin" />
                </div>
            )}
        </button>
    );
};

export default FavoriteButton;