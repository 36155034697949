import React from 'react';
import { Edit2, Trash2, Loader2 } from 'lucide-react';
import { CardContent } from '../ui/card';

const WineTable = ({ wines, loading, onRefresh, onEdit }) => {
  const handleEdit = (wine) => {
    onEdit(wine);
  };

  const handleDelete = async (wineId) => {
    if (!window.confirm('Are you sure you want to delete this wine?')) return;

    try {
      const response = await fetch(`/api/wines/${wineId}`, {
        method: 'DELETE'
      });

      if (response.ok) {
        onRefresh();
      }
    } catch (error) {
      console.error('Error deleting wine:', error);
    }
  };

  if (loading) {
    return (
      <CardContent className="p-6 text-center">
        <div className="animate-spin w-6 h-6 border-2 border-[#822727] border-t-transparent rounded-full mx-auto"></div>
        <p className="mt-2 text-gray-500">Loading wines...</p>
      </CardContent>
    );
  }

  if (wines.length === 0) {
    return (
      <CardContent className="p-6 text-center">
        <p className="text-gray-500">No wines found</p>
      </CardContent>
    );
  }

  return (
    <div className="w-full">
      <div className="hidden sm:block"> {/* Desktop view */}
        <table className="w-full">
          <thead>
            <tr className="border-b">
              <th className="text-left p-4">Name</th>
              <th className="text-left p-4">Type</th>
              <th className="text-left p-4">Winery</th>
              <th className="text-left p-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {wines.map((wine) => (
              <tr key={wine.id} className="border-b hover:bg-gray-50">
                <td className="p-4">{wine.name}</td>
                <td className="p-4">{wine.type}</td>
                <td className="p-4">{wine.winery.name}</td>
                <td className="p-4">
                  <div className="flex gap-2">
                    <button
                      onClick={() => handleEdit(wine)}
                      className="p-2 hover:bg-gray-100 rounded-full"
                    >
                      <Edit2 className="w-4 h-4 text-gray-500" />
                    </button>
                    <button
                      onClick={() => handleDelete(wine.id)}
                      className="p-2 hover:bg-gray-100 rounded-full"
                    >
                      <Trash2 className="w-4 h-4 text-red-500" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Mobile view */}
      <div className="sm:hidden">
        {wines.map((wine) => (
          <div key={wine.id} className="border-b p-4 space-y-2">
            <div className="flex justify-between items-start">
              <div>
                <h3 className="font-medium">{wine.name}</h3>
                <p className="text-sm text-gray-600">
                  {wine.vintage} • {wine.region}
                </p>
              </div>
              <div className="flex gap-2">
                <button
                  onClick={() => handleEdit(wine)}
                  className="p-2 hover:bg-gray-100 rounded-full"
                >
                  <Edit2 className="w-4 h-4 text-gray-500" />
                </button>
                <button
                  onClick={() => handleDelete(wine.id)}
                  className="p-2 hover:bg-gray-100 rounded-full"
                >
                  <Trash2 className="w-4 h-4 text-red-500" />
                </button>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-sm font-medium">Quantity:</span>
              <span className="text-sm">{wine.quantity}</span>
            </div>
          </div>
        ))}
      </div>

      {wines.length === 0 && !loading && (
        <div className="text-center py-8 text-gray-500">
          No wines found
        </div>
      )}
      
      {loading && (
        <div className="text-center py-8">
          <Loader2 className="w-6 h-6 animate-spin mx-auto" />
        </div>
      )}
    </div>
  );
};

export default WineTable;