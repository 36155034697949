// components/WineryMarker.jsx
import React from 'react';
import { Wine, GlassWater, Grape, UtensilsCrossed, Clock } from 'lucide-react';
import { getBusynessLevel } from '../utils/busynessUtil';

const StatusBorder = ({ level }) => {
  // Color schemes for different statuses
  const statusStyles = {
    0: 'border-gray-400', // closed
    1: 'border-green-500', // quiet
    2: 'border-yellow-500', // moderate/busy
    3: 'border-red-500' // very busy
  };

  const statusGlow = {
    0: 'shadow-gray-400/50',  // closed
    1: 'shadow-green-500/50', // quiet
    2: 'shadow-yellow-500/50', // moderate
    3: 'shadow-red-500/50'  // busy
  };

  return (
    <div className={`
      absolute -inset-1 rounded-full 
      border-2 ${statusStyles[level]}
      shadow-lg ${statusGlow[level]}
      transition-all duration-300
    `} />
  );
};

const FeatureIcons = ({ features }) => {
  const iconMap = {
    mcc: { icon: GlassWater, label: 'MCC' },
    restaurant: { icon: UtensilsCrossed, label: 'Restaurant' },
    vineyard: { icon: Grape, label: 'Vineyard Tours' },
    tasting: { icon: Wine, label: 'Wine Tasting' }
  };

  const getFeatureIcons = () => {
    const icons = [];
    const featuresLower = features.map(f => f.toLowerCase());

    if (featuresLower.some(f => f.includes('mcc'))) {
      icons.push('mcc');
    }
    if (featuresLower.some(f => f.includes('restaurant'))) {
      icons.push('restaurant');
    }
    if (featuresLower.some(f => f.includes('vineyard') || f.includes('cellar'))) {
      icons.push('vineyard');
    }
    if (featuresLower.some(f => f.includes('tasting'))) {
      icons.push('tasting');
    }

    return icons;
  };

  const activeIcons = getFeatureIcons();

  return (
    <div className="absolute -bottom-6 left-1/2 transform -translate-x-1/2 flex -space-x-1">
      {activeIcons.map((type, index) => {
        const IconComponent = iconMap[type].icon;
        return (
          <div 
            key={type} 
            className="w-6 h-6 bg-white rounded-full shadow-lg p-1 border border-gray-100
              hover:scale-110 hover:z-10 transition-all duration-200"
            title={iconMap[type].label}
          >
            <IconComponent className="w-full h-full text-[#822727]" />
          </div>
        );
      })}
    </div>
  );
};

const WineryMarker = ({ winery, isSelected, onClick }) => {
  const busyness = winery ? getBusynessLevel(winery) : { status: 'unknown', level: 0 };
  const baseStyles = "w-14 h-14 cursor-pointer transform transition-all duration-300";
  const selectedStyles = isSelected ? "scale-110" : "hover:scale-105";

  const getStatusText = (status) => {
    switch(status) {
      case 'closed': return '🔒 Closed';
      case 'quiet': return '✨ Quiet';
      case 'moderate': return '👥 Moderate';
      case 'busy': return '🔥 Busy';
      case 'very busy': return '🔥 Very Busy';
      default: return status;
    }
  };

  return (
    <div className={`${baseStyles} ${selectedStyles} group relative`} onClick={onClick}>
      <div className="relative">
        {/* Status Border */}
        {winery && <StatusBorder level={busyness.level} />}

        {/* Main Marker Background */}
        <div className={`absolute inset-0 rounded-full ${
          isSelected ? 'bg-[#822727]' : 'bg-white'
        } shadow-md`} />
        
        {/* Main Icon */}
        <div className="relative p-3">
          <Wine className={`w-8 h-8 ${isSelected ? 'text-white' : 'text-[#822727]'}`} />
        </div>

        {/* Selection Animation */}
        {isSelected && (
          <div className="absolute inset-0 rounded-full bg-[#822727] animate-ping opacity-20" />
        )}
        
        {/* Bottom Pointer */}
        <div className={`absolute -bottom-2 left-1/2 transform -translate-x-1/2 
          border-8 border-transparent ${
            isSelected ? 'border-t-[#822727]' : 'border-t-white'
          }`} 
          style={{ width: 0, height: 0 }}
        />
        
        {/* Feature Icons */}
        {winery && <FeatureIcons features={winery.features} />}

        {/* Hover Tooltip */}
        <div className="opacity-0 group-hover:opacity-100 transition-opacity absolute -top-16 left-1/2 transform -translate-x-1/2">
          <div className="bg-white text-gray-800 text-sm px-3 py-2 rounded-lg shadow-lg whitespace-nowrap">
            <div className="font-medium mb-1">{winery.name}</div>
            <div className="flex items-center gap-2 text-sm text-gray-600">
              <Clock className="w-3 h-3" />
              <span>{getStatusText(busyness.status)}</span>
            </div>
          </div>
          {/* Tooltip Arrow */}
          <div className="absolute left-1/2 transform -translate-x-1/2 -bottom-2
            border-8 border-transparent border-t-white" 
            style={{ width: 0, height: 0 }}
          />
        </div>
      </div>
    </div>
  );
};

export default WineryMarker;