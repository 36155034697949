// components/Stats.jsx
import React from 'react';
import AnimatedCounter from './AnimatedCounter';

const Stats = () => {
  const stats = [
    { end: 50, label: 'Partner Wineries' },
    { end: 1000, label: 'Active Users' },
    { end: 5000, label: 'Tastings Booked' },
  ];

  return (
    <div className="bg-white py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-3">
          {stats.map((stat, index) => (
            <AnimatedCounter key={index} {...stat} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Stats;