import React from 'react';

const FormField = ({
    prop,
    label,
    formik,
    disabled = false,
    small = false,
    type = 'text',
    maxLength = 65535,
    multiline = false,
    loading = false,
    placeholder=""
}) => {
    if (loading) {
        return (
            <div className={`${small ? 'w-1/2' : 'w-full'} pt-1`}>
                <div className="h-5 bg-gray-200 rounded animate-pulse" />
            </div>
        );
    }

    if (disabled) {
        return (
            <div className={`${small ? 'w-1/2' : 'w-full'} pt-1`}>
                <div className="border border-gray-200 rounded-lg p-4">
                    <pre className="text-sm font-normal whitespace-pre-wrap">
                        {label}: {formik?.values && formik?.values[prop]}
                    </pre>
                </div>
            </div>
        );
    }

    return (
        <div className={`${small ? 'w-1/2' : 'w-full'} pt-1`}>
            <div className="relative">

                {multiline && <textarea
                    id={prop}
                    type={type}
                    name={prop}
                    value={(formik?.values && formik?.values[prop]) || ''}
                    onChange={formik?.handleChange}
                    className={`
                        w-full px-3 py-2 border rounded-lg 
                        focus:ring-[#822727] focus:border-[#822727]
                        ${formik?.errors && formik?.errors[prop] ? 'border-red-500' : 'border-gray-300'}
                        ${multiline ? 'min-h-[100px]' : ''}
                    `}
                    rows={4}
                    placeholder={placeholder}
                />}
                {!multiline && <input
                    id={prop}
                    type={type}
                    name={prop}
                    value={(formik?.values && formik?.values[prop]) || ''}
                    onChange={formik?.handleChange}
                    maxLength={maxLength}
                    rows
                    disabled={disabled}
                    className={`
                        w-full px-3 py-2 border rounded-lg 
                        focus:ring-[#822727] focus:border-[#822727]
                        ${formik?.errors && formik?.errors[prop] ? 'border-red-500' : 'border-gray-300'}
                        ${multiline ? 'min-h-[100px]' : ''}
                    `}
                    placeholder={placeholder}
                />}
                <label
                    htmlFor={prop}
                    className="absolute -top-2 left-2 px-1 text-sm bg-white text-gray-600"
                >
                    {label}
                </label>
            </div>
            {formik?.errors && formik?.errors[prop] && (
                <p className="mt-1 text-sm text-red-500">
                    {formik.errors[prop]}
                </p>
            )}
        </div>
    );
};

export default FormField;