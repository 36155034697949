import RoleBasedNavigation from '../components/navigation/RoleBasedNavigation';

const WineryStaffDashboard = () => {
    return (
        <div className="flex">
            <RoleBasedNavigation />
            <main className="flex-1 bg-gray-50 min-h-screen">
                <h2 className="text-2xl font-bold p-6">Staff Dashboard</h2>
                <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Today's Bookings */}
                    <div className="bg-white rounded-lg shadow p-6">
                        <h3 className="font-semibold mb-4">Today's Bookings</h3>
                        {/* Bookings content */}
                    </div>

                    {/* Tasks */}
                    <div className="bg-white rounded-lg shadow p-6">
                        <h3 className="font-semibold mb-4">My Tasks</h3>
                        {/* Tasks content */}
                    </div>
                </div>
            </main>
        </div>
    );
};

export {
    WineryStaffDashboard
};