// src/services/api.js
import { transformWineryData } from '../utils/dataTransformers';
import { ServiceAPI } from '../services/url';
// src/services/api.js
import { getAuth } from 'firebase/auth';

const API_BASE_URL = ServiceAPI;

// Helper function to get the current Firebase auth token
const getAuthToken = async () => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    return null;
    //    throw new Error('No authenticated user');
  }

  return await user.getIdToken();
};

// Helper function to build query string
const buildQueryString = (params) => {
  const query = Object.entries(params)
    .filter(([_, value]) => value !== undefined && value !== null && value !== '')
    .map(([key, value]) => {
      // Handle arrays (like features)
      if (Array.isArray(value)) {
        return `${key}=${value.join(',')}`;
      }
      // Handle booleans
      if (typeof value === 'boolean') {
        return `${key}=${value}`;
      }
      return `${key}=${encodeURIComponent(value)}`;
    })
    .join('&');
  return query ? `?${query}` : '';
};

// Helper function to make authenticated API requests
const authenticatedRequest = async (endpoint, options = {}) => {
  try {
    const token = await getAuthToken();

    const headers = {
      ...options.headers
    };

    if (!(options.body instanceof FormData)) {
      headers['Content-Type'] = 'application/json';
    }

    console.log(headers);

    if (token) headers['Authorization'] = `Bearer ${token}`;

    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      ...options,
      headers
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'API request failed');
    }

    return await response.json();
  } catch (error) {
    console.error(`API request failed: ${endpoint}`, error);
    throw error;
  }
};

export const WineryService = {
  // Get all wineries with optional filters
  getWineries: async (filters = {}) => {
    const queryString = buildQueryString({
      page: filters.page || 1,
      limit: filters.limit || 10,
      region: filters.region,
      features: filters.features,
      isOpen: filters.isOpen,
      rating: filters.rating,
      search: filters.search,
      priceRange: filters.priceRange
    });

    return await authenticatedRequest(`/wineries${queryString}`, {
      method: 'GET'
    });
  },

  searchWineries: async (term) => {
    return await authenticatedRequest(`/wineries?search=${encodeURIComponent(term)}&limit=5`, {
      method: 'GET'
    });
  },

  // Get single winery by ID
  getWinery: async (id) => {
    return await authenticatedRequest(`/wineries/${id}`, {
      method: 'GET'
    });
  },

  getDistinctRegions: async () => {
    return await authenticatedRequest('/wineries/regions', {
      method: 'GET'
    });
  },

  // Get single winery by ID
  getNearbyWineries: async (lat, lng, radius, limit, isOpen, minRating) => {
    const queryString = buildQueryString({
      lat,
      lng,
      radius,
      limit,
      isOpen,
      minRating
    });

    return await authenticatedRequest(`/wineries/nearby${queryString}`, {
      method: 'GET'
    });
  },

  // Create new winery
  createWinery: async (wineryData) => {
    wineryData = {
      ...wineryData,
      location: {
        address: wineryData.address,
        city: 'CPT',
        province: 'WP',
        postalCode: '1234',
        coordinates: wineryData.coordinates
      }
    };
    return await authenticatedRequest('/wineries', {
      method: 'POST',
      body: JSON.stringify(wineryData)
    });
  },

  // Update winery
  updateWinery: async (id, wineryData) => {
    return await authenticatedRequest(`/wineries/${id}`, {
      method: 'PUT',
      body: JSON.stringify(wineryData)
    });
  },

  // Delete winery
  deleteWinery: async (id) => {
    return await authenticatedRequest(`/wineries/${id}`, {
      method: 'DELETE'
    });
  }
};

export const UserService = {
  // Get user profile
  getProfile: async () => {
    return await authenticatedRequest('/users/profile', {
      method: 'GET'
    });
  },

  // Update user profile
  updateProfile: async (profileData) => {
    return await authenticatedRequest('/users/profile', {
      method: 'PUT',
      body: JSON.stringify(profileData)
    });
  },

  // Get favorite wineries
  getFavoriteWineries: async () => {
    return await authenticatedRequest('/users/favorites/wineries', {
      method: 'GET'
    });
  },

  // Get favorite wineries
  getFavoriteWines: async () => {
    return await authenticatedRequest('/users/favorites/wines', {
      method: 'GET'
    });
  },

  // Add favorite winery
  setFavoriteWinery: async (wineryId, isFavorite) => {
    return await authenticatedRequest(`/users/favorites/winery/${wineryId}`, {
      method: isFavorite ? 'DELETE' : 'POST',
    });
  },

  // Remove favorite winery
  removeFavoriteWinery: async (wineryId) => {
    return await authenticatedRequest(`/users/favorites/winery/${wineryId}`, {
      method: 'DELETE'
    });
  },

  checkFavoriteWineStatus: async (wineId) => {
    return await authenticatedRequest(`/users/favorites/wines/${wineId}`, {
      method: 'GET'
    });
  },

  checkFavoriteWineryStatus: async (wineId) => {
    return await authenticatedRequest(`/users/favorites/wineries/${wineId}`, {
      method: 'GET'
    });
  },

  setFavoriteWineStatus: async (wineId, isFavorite) => {
    return await authenticatedRequest(`/users/favorites/wines/${wineId}`, {
      method: isFavorite ? 'DELETE' : 'POST',
    });
  },

};

export const AdminService = {
  // Get all users (admin only)
  getAllUsers: async (filters = {}) => {
    const queryString = buildQueryString(filters);
    return await authenticatedRequest(`/users/users${queryString}`, {
      method: 'GET'
    });
  },

  // Assign role to user (admin only)
  assignRole: async (userId, roleData) => {
    return await authenticatedRequest('/users/assign-role', {
      method: 'POST',
      body: JSON.stringify({ uid: userId, ...roleData })
    });
  },

  // Assign role to user (admin only)
  getStats: async () => {
    return await authenticatedRequest('/admin/stats', {
      method: 'GET'
    });
  }
};

export const WineService = {
  // Analyze wine labels with AI
  analyzeLabels: async (frontLabel, backLabel) => {
    try {
      const formData = new FormData();
      formData.append('frontLabel', frontLabel);
      formData.append('backLabel', backLabel);

      return await authenticatedRequest('/wines/analyze-labels', {
        method: 'POST',
        body: formData,
        // Don't set Content-Type header - let browser set it with boundary for FormData
        headers: {}
      });
    } catch (error) {
      throw new Error(`Error analyzing labels: ${error.message}`);
    }
  },

  // Check if winery exists and create if needed
  processWinery: async (wineryInfo) => {
    try {
      // Check if winery exists
      const searchData = await authenticatedRequest(
        `/wineries/search?name=${encodeURIComponent(wineryInfo.name)}`,
        { method: 'GET' }
      );

      if (searchData.exists) {
        return searchData.winery;
      }

      // Create new winery if it doesn't exist
      const createData = await authenticatedRequest('/wineries', {
        method: 'POST',
        body: JSON.stringify(wineryInfo)
      });

      return createData.winery;
    } catch (error) {
      throw new Error(`Error processing winery: ${error.message}`);
    }
  },

  // Create new wine
  createWine: async (wineData) => {
    try {
      const response = await authenticatedRequest(`/wineries/${wineData.wineryId}/wines`, {
        method: 'POST',
        body: JSON.stringify(wineData)
      });

      return response;
    } catch (error) {
      throw new Error(`Error creating wine: ${error.message}`);
    }
  },

  // Check if wine exists
  checkWineExists: async (name, vintage) => {
    try {
      const data = await authenticatedRequest(
        `/wines/search?name=${encodeURIComponent(name)}&vintage=${vintage}`,
        { method: 'GET' }
      );
      return data.exists;
    } catch (error) {
      throw new Error(`Error checking wine existence: ${error.message}`);
    }
  },

  // Process entire label workflow
  processLabels: async (frontLabel, backLabel) => {
    try {
      // Step 1: Analyze labels with AI
      const analysisResult = await WineService.analyzeLabels(frontLabel, backLabel);
      const { wineryInfo, wineInfo } = analysisResult;

      // Return combined results
      return {
        wineInfo,
        wineryInfo,
        success: true
      };
    } catch (error) {
      throw new Error(`Label processing failed: ${error.message}`);
    }
  },

  getWines: async (filters = {}) => {
    const queryString = buildQueryString({
      page: filters.page || 1,
      limit: filters.limit || 10,
      search: filters.search,
    });

    return await authenticatedRequest(`/wines${queryString}`, {
      method: 'GET'
    });
  },
};

export const ReviewService = {
  // Get all reviews for a winery
  getWineryReviews: async (wineryId, filters = {}) => {
    const queryString = buildQueryString({
      page: filters.page || 1,
      limit: filters.limit || 10,
      rating: filters.rating,
      status: filters.status
    });

    return await authenticatedRequest(`/reviews/winery/all/${wineryId}${queryString}`, {
      method: 'GET'
    });
  },

  // Get a single review by ID
  getUserWineReviews: async (wineId) => {
    return await authenticatedRequest(`/reviews/wine/${wineId}`, {
      method: 'GET'
    });
  },

  // Get a single review by ID
  getWineReviews: async (wineId, filters = {}) => {
    const queryString = buildQueryString({
      page: filters.page || 1,
      limit: filters.limit || 10
    });

    return await authenticatedRequest(`/reviews/wine/all/${wineId}${queryString}`);
  },

  // Get a single review by ID
  getReview: async (reviewId) => {
    return await authenticatedRequest(`/reviews/${reviewId}`, {
      method: 'GET'
    });
  },

  // Create a new review
  createWineReview: async (wineId, reviewData) => {
    return await authenticatedRequest(`/reviews/wine/${wineId}`, {
      method: 'POST',
      body: JSON.stringify(reviewData)
    });
  },

  // Create a new review
  createWineryReview: async (wineryId, reviewData) => {
      return await authenticatedRequest(`/reviews/winery/${wineryId}`, {
        method: 'POST',
        body: JSON.stringify(reviewData)
      });
  },

  // Update an existing review
  updateReview: async (reviewId, reviewData) => {
    return await authenticatedRequest(`/reviews/${reviewId}`, {
      method: 'PUT',
      body: JSON.stringify(reviewData)
    });
  },

  // Delete a review
  deleteReview: async (reviewId) => {
    return await authenticatedRequest(`/reviews/${reviewId}`, {
      method: 'DELETE'
    });
  },

  // Get user's reviews
  getUserReviews: async (filters = {}) => {
    const queryString = buildQueryString({
      page: filters.page || 1,
      limit: filters.limit || 10,
      status: filters.status
    });

    return await authenticatedRequest(`/users/reviews${queryString}`, {
      method: 'GET'
    });
  },

  // Respond to a review (winery staff/admin only)
  respondToReview: async (reviewId, responseData) => {
    return await authenticatedRequest(`/reviews/${reviewId}/respond`, {
      method: 'POST',
      body: JSON.stringify({
        content: responseData.content
      })
    });
  },

  // Moderate a review (admin only)
  moderateReview: async (reviewId, moderationData) => {
    return await authenticatedRequest(`/reviews/${reviewId}/moderate`, {
      method: 'POST',
      body: JSON.stringify({
        status: moderationData.status,
        moderationNotes: moderationData.notes
      })
    });
  },

  // Helper method to format review data
  formatReviewData: (formData) => {
    return {
      rating: Number(formData.rating),
      title: formData.title.trim(),
      content: formData.content.trim(),
      visitDate: formData.visitDate,
      wineRatings: formData.wineRatings?.map(rating => ({
        wine: rating.wineId,
        rating: Number(rating.rating),
        notes: rating.notes?.trim()
      })) || [],
      photos: formData.photos || []
    };
  }
};
