import React from 'react';

const Wineries = () => {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Wineries</h1>
      {/* Winery listing content */}
    </div>
  );
};

export default Wineries;
