import React, { useState, useEffect } from 'react';
import { Search } from 'lucide-react';
import _ from 'lodash';
import { WineryService } from '../services/api';
import WinerySearch from './WinerySearch';

const WineForm = ({ formik }) => {
  const [wineries, setWineries] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentWineryId, setCurrentWineryId] = useState();
  const [showWineryResults, setShowWineryResults] = useState(false);
  const [selectedWinery, setSelectedWinery] = useState(null);

  // Load winery if wineryId is provided
  useEffect(() => {
    if (currentWineryId != formik.values.wineryId) {
      loadWinery(formik.values.wineryId);
      setCurrentWineryId(formik.values.wineryId);
    }
  }, [formik.values]);

  const loadWinery = async (id) => {
    try {
      console.log('Loading winery:', id);
      const winery = await WineryService.getWinery(id);
      setSelectedWinery(winery);
      setSearchTerm(winery.name);
    } catch (error) {
      console.error('Error loading winery:', error);
    }
  };

  // Debounced search function
  const debouncedSearch = _.debounce(async (term) => {
    if (term.length < 2) return;

    try {
      const results = await WineryService.searchWineries(term);
      setWineries(results);
      setShowWineryResults(true);
    } catch (error) {
      console.error('Error searching wineries:', error);
    }
  }, 300);

  return (
    <div className="space-y-6">
      {/* Basic Information */}
      <div>
        <h3 className="text-lg font-medium mb-4">Basic Information</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Wine Name
            </label>
            <input
              type="text"
              value={formik.values.name}
              onChange={(e) => formik.setValues(prev => ({ ...prev, name: e.target.value }))}
              className="w-full px-3 py-2 border rounded-lg focus:ring-[#822727] focus:border-[#822727]"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Type
            </label>
            <select
              value={formik.values.type}
              onChange={(e) => formik.setValues(prev => ({ ...prev, type: e.target.value }))}
              className="w-full px-3 py-2 border rounded-lg focus:ring-[#822727] focus:border-[#822727]"
              required
            >
              <option value="">Select Type</option>
              <option value="red">Red</option>
              <option value="white">White</option>
              <option value="rosé">Rosé</option>
              <option value="dry rosé">Dry Rosé</option>
              <option value="sparkling">Sparkling</option>
              <option value="dessert">Dessert</option>
              <option value="fortified">Fortified</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Vintage
            </label>
            <input
              type="number"
              value={formik.values.vintage}
              onChange={(e) => formik.setValues(prev => ({ ...prev, vintage: e.target.value }))}
              className="w-full px-3 py-2 border rounded-lg focus:ring-[#822727] focus:border-[#822727]"
              min="1900"
              max={new Date().getFullYear()}
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Varietal
            </label>
            <input
              type="text"
              value={formik.values.varietal}
              onChange={(e) => formik.setValues(prev => ({ ...prev, varietal: e.target.value }))}
              className="w-full px-3 py-2 border rounded-lg focus:ring-[#822727] focus:border-[#822727]"
              required
            />
          </div>
        </div>
      </div>

      {/* Winery Selection */}
      <div className="relative">
        <div>
          <h3 className="text-lg font-medium mb-4">Winery</h3>
          <WinerySearch
            selectedWinery={selectedWinery}
            onWinerySelect={(winery) => {
              console.log('Selected winery:', winery);
              formik.setValues(prev => ({
                ...prev,
                wineryId: winery._id
              }));
              setSelectedWinery(winery);
            }}
          />
        </div>
      </div>

      {/* Technical Details */}
      <div>
        <h3 className="text-lg font-medium mb-4">Technical Details</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Alcohol (%)
            </label>
            <input
              type="number"
              value={formik.values.technicalDetails?.alcohol}
              onChange={(e) => formik.setValues(prev => ({
                ...prev,
                technicalDetails: {
                  ...prev.technicalDetails,
                  alcohol: e.target.value
                }
              }))}
              className="w-full px-3 py-2 border rounded-lg focus:ring-[#822727] focus:border-[#822727]"
              step="0.1"
              min="0"
              max="20"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              pH
            </label>
            <input
              type="number"
              value={formik.values.technicalDetails?.pH}
              onChange={(e) => formik.setValues(prev => ({
                ...prev,
                technicalDetails: {
                  ...prev.technicalDetails,
                  pH: e.target.value
                }
              }))}
              className="w-full px-3 py-2 border rounded-lg focus:ring-[#822727] focus:border-[#822727]"
              step="0.01"
              min="0"
              max="14"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Acidity (g/L)
            </label>
            <input
              type="number"
              value={formik.values.technicalDetails?.acidity}
              onChange={(e) => formik.setValues(prev => ({
                ...prev,
                technicalDetails: {
                  ...prev.technicalDetails,
                  acidity: e.target.value
                }
              }))}
              className="w-full px-3 py-2 border rounded-lg focus:ring-[#822727] focus:border-[#822727]"
              step="0.1"
              min="0"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Residual Sugar (g/L)
            </label>
            <input
              type="number"
              value={formik.values.technicalDetails?.residualSugar}
              onChange={(e) => formik.setValues(prev => ({
                ...prev,
                technicalDetails: {
                  ...prev.technicalDetails,
                  residualSugar: e.target.value
                }
              }))}
              className="w-full px-3 py-2 border rounded-lg focus:ring-[#822727] focus:border-[#822727]"
              step="0.1"
              min="0"
            />
          </div>
        </div>
      </div>

      {/* Description */}
      <div>
        <h3 className="text-lg font-medium mb-4">Description</h3>
        <textarea
          value={formik.values.description}
          onChange={(e) => formik.setValues(prev => ({ ...prev, description: e.target.value }))}
          className="w-full px-3 py-2 border rounded-lg focus:ring-[#822727] focus:border-[#822727]"
          rows={4}
          placeholder="Enter wine description..."
        />
      </div>
    </div>
  );
};

export default WineForm;