import { useState, useEffect } from 'react';
import { WineryService } from '../services/api';

export const useWineries = (initialFilters = { limit: 200 }) => {
  const [wineries, setWineries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    const fetchWineries = async () => {
      try {
        setLoading(true);
        const data = await WineryService.getWineries({ limit: 200 });
        console.log('API Response new:', data);
        setWineries(data.wineries);
        setError(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchWineries();
  }, [filters]);

  const updateFilters = (newFilters) => {
    setFilters(prev => ({ ...prev, ...newFilters }));
  };

  return { wineries, loading, error, filters, updateFilters };
};
