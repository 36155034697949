// src/components/MapFilters.jsx
import React, { useState } from 'react';
import {
  Wine, GlassWater, Grape, UtensilsCrossed, Filter, X, ChevronDown, MapPin
} from 'lucide-react';

const FilterButton = ({ active, children, onClick }) => (
  <button
    onClick={onClick}
    className={`px-3 py-1.5 rounded-full text-sm font-medium transition-colors
      ${active
        ? 'bg-[#822727] text-white'
        : 'bg-white text-gray-700 hover:bg-gray-100 border border-gray-200'
      }`}
  >
    <div className="flex items-center gap-2">
      {children}
    </div>
  </button>
);

const MapFilters = ({ filters = {}, onChange, name = "absolute top-4 left-4 z-10" }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Safely check for active filters
  const activeFilterCount = Object.entries(filters || {}).filter(([_, value]) => {
    if (Array.isArray(value)) return value.length > 0;
    if (typeof value === 'boolean') return value;
    return value !== undefined && value !== '' && value !== null;
  }).length;

  const checkFeature = (feature) => {
    if (!filters.features) return false;
    if (typeof filters.features === 'string') {
      return filters.features.split(',').includes(feature);
    }
    if (Array.isArray(filters.features)) {
      return filters.features.includes(feature);
    }
    return false;
  };

  const toggleFilter = (category, value) => {
    if (!onChange) return;

    if (category === 'features') {
      // Handle features as a comma-separated string
      const currentFeatures = (filters.features || '').split(',').filter(Boolean);
      let newFeatures;

      if (currentFeatures.includes(value)) {
        newFeatures = currentFeatures.filter(f => f !== value);
      } else {
        newFeatures = [...currentFeatures, value];
      }

      onChange({
        ...filters,
        features: newFeatures.join(',')
      });
    } else {
      // Handle other filters
      onChange({
        ...filters,
        [category]: value
      });
    }
  };

  const clearFilters = () => {
    onChange({
      region: '',
      features: '',
      rating: '',
      isOpen: false,
      priceRange: ''
    });
  };

  return (
    <div className={name}>
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="bg-white rounded-lg shadow-lg px-4 py-2 w-full flex items-center justify-between hover:bg-gray-50 transition-colors"
      >
        <div className="flex items-center gap-2">
          <Filter className="w-4 h-4" />
          <span className="font-bold">Filters</span>
          {activeFilterCount > 0 && (
            <span className="bg-[#822727] text-white px-2 py-0.5 rounded-full text-xs">
              {activeFilterCount}
            </span>
          )}
        </div>
        <ChevronDown
          className={`w-4 h-4 transition-transform duration-200 ${isExpanded ? 'transform rotate-180' : ''
            }`}
        />
      </button>

      <div className={`
        mt-2 bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-200 origin-top
        ${isExpanded
          ? 'opacity-100 transform scale-y-100 max-h-[500px]'
          : 'opacity-0 transform scale-y-0 max-h-0'
        }
      `}>
        <div className="p-4">
          {activeFilterCount > 0 && (
            <div className="flex justify-end mb-4">
              <button
                onClick={clearFilters}
                className="text-sm text-gray-500 hover:text-gray-700 flex items-center gap-1"
              >
                <X className="w-4 h-4" />
                Clear all
              </button>
            </div>
          )}

          {/* Region Filter */}
          <div className="mb-4">
            <h4 className="text-sm font-medium mb-2 text-gray-700">Region</h4>
            <div className="flex flex-wrap gap-2">
              <FilterButton
                active={filters.region === 'Robertson'}
                onClick={() => toggleFilter('region', 'Robertson')}
              >
                <MapPin className="w-4 h-4" />
                Robertson
              </FilterButton>
              <FilterButton
                active={filters.region === 'Stellenbosch'}
                onClick={() => toggleFilter('region', 'Stellenbosch')}
              >
                <MapPin className="w-4 h-4" />
                Stellenbosch
              </FilterButton>
            </div>
          </div>

          {/* Features */}
          <div className="mb-4">
            <h4 className="text-sm font-medium mb-2 text-gray-700">Features</h4>
            <div className="flex flex-wrap gap-2">
              <FilterButton
                active={checkFeature('mcc')}
                onClick={() => toggleFilter('features', 'mcc')}
              >
                <GlassWater className="w-4 h-4" />
                MCC
              </FilterButton>
              <FilterButton
                active={checkFeature('restaurant')}
                onClick={() => toggleFilter('features', 'restaurant')}
              >
                <UtensilsCrossed className="w-4 h-4" />
                Restaurant
              </FilterButton>
              <FilterButton
                active={checkFeature('vineyard')}
                onClick={() => toggleFilter('features', 'vineyard')}
              >
                <Grape className="w-4 h-4" />
                Vineyard Tours
              </FilterButton>
              <FilterButton
                active={checkFeature('tasting')}
                onClick={() => toggleFilter('features', 'tasting')}
              >
                <Wine className="w-4 h-4" />
                Wine Tasting
              </FilterButton>
            </div>
          </div>

          {/* Price Range */}
          <div className="mb-4">
            <h4 className="text-sm font-medium mb-2 text-gray-700">Price Range</h4>
            <select
              value={filters.priceRange || ''}
              onChange={(e) => toggleFilter('priceRange', e.target.value)}
              className="w-full border rounded-lg px-3 py-2"
            >
              <option value="">All Prices</option>
              <option value="0-200">Under R200</option>
              <option value="200-500">R200 - R500</option>
              <option value="500-1000">R500 - R1000</option>
              <option value="1000-">R1000+</option>
            </select>
          </div>

          {/* Rating Filter */}
          <div className="mb-4">
            <h4 className="text-sm font-medium mb-2 text-gray-700">Minimum Rating</h4>
            <select
              value={filters.rating || ''}
              onChange={(e) => toggleFilter('rating', e.target.value)}
              className="w-full border rounded-lg px-3 py-2"
            >
              <option value="">Any Rating</option>
              <option value="4.5">4.5+ Stars</option>
              <option value="4">4+ Stars</option>
              <option value="3.5">3.5+ Stars</option>
            </select>
          </div>

          {/* Open Now Toggle */}
          <div>
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={filters.isOpen || false}
                onChange={(e) => toggleFilter('isOpen', e.target.checked)}
                className="rounded text-[#822727]"
              />
              <span>Open Now</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapFilters;