import React, { useState, useEffect } from 'react';
import { Plus } from 'lucide-react';
import WineTable from './WineTable';
import WineProfile from '../WineProfile';
import LabelProcessor from '../LabelProcessor';
import { Card } from '../ui/card';
import { WineService } from '../../services/api';
import { useFormik } from 'formik';
import { X, Camera, Search } from 'lucide-react';
import _ from 'lodash';

const WineManagement = () => {
  const [wines, setWines] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedWine, setSelectedWine] = useState(null);
  const [selectionMethod, setSelectionMethod] = useState(null); // 'scan' or 'search'
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0
  });
  const [showEditModal, setShowEditModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      type: '',
      vintage: new Date().getFullYear(),
      varietal: '',
      price: '',
      description: '',
      wineryId: '',
      technicalDetails: {
        alcohol: '',
        pH: '',
        acidity: '',
        residualSugar: ''
      },
      frontLabel: null,
      backLabel: null
    }
  });

  useEffect(() => {
    if (!selectedWine && formik.values.name) {
      setSelectedWine({
        ...formik.values
      });
    }
  }, [formik]);

  const WineSearch = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);

    const searchWines = async (searchQuery) => {
      if (!searchQuery.trim()) return;

      setLoading(true);
      try {
        const response = await WineService.getWines({ search: searchQuery });
        setResults(response.wines);
      } catch (error) {
        console.error('Error searching wines:', error);
      } finally {
        setLoading(false);
      }
    };

    const handleSearch = _.debounce(searchWines, 300);

    return (
      <div className="space-y-4">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="text"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
              handleSearch(e.target.value);
            }}
            placeholder="Search wines by name, winery, or variety..."
            className="w-full pl-10 pr-4 py-2 border rounded-lg"
          />
        </div>

        {loading ? (
          <div className="text-center py-4">
            <div className="animate-spin w-6 h-6 border-2 border-[#822727] border-t-transparent rounded-full mx-auto"></div>
          </div>
        ) : (
          <div className="max-h-64 overflow-y-auto space-y-2">
            {results.map((wine) => (
              <button
                key={wine._id}
                onClick={() => { formik.setValues({ ...wine }); }
                }
                className="w-full text-left p-3 hover:bg-gray-50 rounded-lg transition-colors border"
              >
                <div className="font-medium">{wine.name}</div>
                <div className="text-sm text-gray-600">
                  {wine.winery.name} · {wine.vintage}
                </div>
              </button>
            ))}
          </div>
        )}
      </div>
    );
  };

  const SelectWineMethod = () => (
    <div className="space-y-6">
      {!selectionMethod ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <button
            onClick={() => setSelectionMethod('scan')}
            className="flex flex-col items-center justify-center p-4 sm:p-6 border-2 border-dashed rounded-lg hover:border-[#822727] hover:bg-[#822727]/5 transition-colors"
          >
            <Camera className="w-6 h-6 sm:w-8 sm:h-8 mb-2 text-[#822727]" />
            <span className="text-sm">Scan Label</span>
          </button>
          <button
            onClick={() => {
              setSelectionMethod('search');
              formik.setFieldValue('name', 'Name Here');
            }}
            className="flex flex-col items-center justify-center p-4 sm:p-6 border-2 border-dashed rounded-lg hover:border-[#822727] hover:bg-[#822727]/5 transition-colors"
          >
            <Search className="w-6 h-6 sm:w-8 sm:h-8 mb-2 text-[#822727]" />
            <span className="text-sm">Manual Add</span>
          </button>
        </div>
      ) : (
        <div>
          <div className="flex justify-between items-center mb-4">
            <h4 className="font-medium">
              {selectionMethod === 'scan' ? 'Scan Wine Label' : 'Manually Add Wine'}
            </h4>
            <button
              onClick={() => setSelectionMethod(null)}
              className="text-sm text-[#822727] hover:text-[#722323]"
            >
              Change Method
            </button>
          </div>

          {selectionMethod === 'scan' ? <LabelProcessor formik={formik} /> : <WineSearch />}
        </div>
      )}
    </div>
  );

  // Fetch wines
  const fetchWines = async () => {
    try {
      setLoading(true);
      const response = await WineService.getWines(
        {
          page: pagination.currentPage,
          limit: 10
        }
      );
      console.log(response);
      setWines(response.wines);
    } catch (error) {
      console.error('Error fetching wines:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWines();
  }, []);

  // Add handler for edit button click
  const handleEditWine = (wine) => {
    formik.setValues(wine);
    setSelectedWine(wine);
    setShowEditModal(true);
  };

  return (
    <div className="p-3 sm:p-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-0 mb-6">
        <h2 className="text-xl sm:text-2xl font-bold">Wine Management</h2>
        <button
          onClick={() => setShowAddModal(true)}
          className="w-full sm:w-auto flex items-center justify-center gap-2 px-4 py-2 bg-[#822727] text-white rounded-lg hover:bg-[#722323]"
        >
          <Plus className="w-4 h-4" />
          Add Wine
        </button>
      </div>

      <Card>
        <WineTable 
          wines={wines} 
          loading={loading} 
          onRefresh={fetchWines}
          onEdit={handleEditWine}
        />
      </Card>

      {showEditModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg w-full max-w-3xl max-h-[90vh] overflow-y-auto">
            <div className="flex items-center justify-between px-4 sm:px-6 py-4 border-b">
              <h3 className="text-base sm:text-lg font-semibold">Edit Wine</h3>
              <button
                onClick={() => {
                  setShowEditModal(false);
                  setSelectedWine(null);
                  formik.resetForm();
                }}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <div className="p-4">
              <WineProfile formik={formik} initEdit />
              <div className="mt-4 flex justify-end">
                <button
                  onClick={async () => {
                    try {
                      await WineService.updateWine(selectedWine.id, formik.values);
                      setShowEditModal(false);
                      setSelectedWine(null);
                      formik.resetForm();
                      fetchWines();
                    } catch (error) {
                      console.error('Error updating wine:', error);
                    }
                  }}
                  className="px-4 py-2 bg-[#822727] text-white rounded-lg hover:bg-[#722323]"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showAddModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg w-full max-w-3xl max-h-[90vh] overflow-y-auto">
            <div className="flex items-center justify-between px-4 sm:px-6 py-4 border-b">
              <h3 className="text-base sm:text-lg font-semibold">Add New Wine</h3>
              <button
                onClick={() => {
                  setShowAddModal(false);
                  setSelectedWine(null);
                  setSelectionMethod(null);
                  formik.resetForm();
                }}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <div className="p-4">
              {!selectedWine ? <SelectWineMethod /> : <WineProfile formik={formik} />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WineManagement;