// src/components/InteractiveMap.jsx
import React, { useState } from 'react';
import Map, { Marker, NavigationControl } from 'react-map-gl';
import WineryMarker from './WineryMarker';
import WineryPopup from './WineryPopup';
import MapFilters from './MapFilters';
import { useWineries } from '../hooks/useWineries';

// Set default mapbox token
const MAPBOX_TOKEN = "pk.eyJ1Ijoiam9oYW5iZWhyZW5zIiwiYSI6ImNtNGdtYjdrMTFnaG4ya3IzejA0ajk4dmwifQ.gAd4vI3A3oHjUwmOOvJKWQ";

// Default center coordinates for the map
const INITIAL_VIEW_STATE = {
  longitude: 19.8842,
  latitude: -33.8012,
  zoom: 12,
  pitch: 0,
  bearing: 0
};

const InteractiveMap = () => {
  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);
  const [selectedWinery, setSelectedWinery] = useState(null);
  const { wineries, loading, error, filters, updateFilters } = useWineries();
  console.log('API Response:', wineries);

  const getWineryType = (winery) => {
    const features = winery.features.map(f => f.toLowerCase());
    if (features.some(f => f.includes('mcc'))) return 'mcc';
    if (features.some(f => f.includes('restaurant'))) return 'restaurant';
    if (features.some(f => f.includes('vineyard'))) return 'vineyard';
    return 'standard';
  };

  const handleMarkerClick = (e, winery) => {
    e.originalEvent.stopPropagation();
    setSelectedWinery(winery);
  };

  return (
    <div className="w-full h-full relative rounded-lg overflow-hidden">
      <Map
        {...viewState}
        onMove={evt => setViewState(evt.viewState)}
        mapStyle="mapbox://styles/mapbox/outdoors-v12"
        mapboxAccessToken={MAPBOX_TOKEN}
        attributionControl={true}
      >
        <NavigationControl position="top-right" />

        {/* Loading State */}
        {loading && (
          <div className="absolute inset-0 bg-white/80 flex items-center justify-center z-50">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#822727]"></div>
          </div>
        )}

        {/* Error State */}
        {error && (
          <div className="absolute inset-0 bg-white/80 flex items-center justify-center z-50">
            <div className="text-red-600 bg-white p-4 rounded-lg shadow">
              Error loading wineries: {error}
            </div>
          </div>
        )}

        {/* Winery Markers */}
        {wineries.map((winery) => (
          <Marker
            key={winery.id}
            longitude={winery.location.coordinates[0]}
            latitude={winery.location.coordinates[1]}
            anchor="bottom"
            onClick={(e) => handleMarkerClick(e, winery)}
          >
            <WineryMarker
              winery={winery}
              type={getWineryType(winery)}
              isSelected={selectedWinery?.id === winery.id}
            />
          </Marker>
        ))}
      </Map>

      {/* Filters */}
      {false && <MapFilters 
        filters={filters}
        onChange={updateFilters}
        className="absolute top-4 left-4 z-10"
      />}

      {/* Results Count */}
      <div className="absolute bottom-4 left-4 bg-white px-3 py-1.5 rounded-full shadow-lg text-sm">
        {loading ? (
          'Loading...'
        ) : (
          `Showing ${wineries.length} wineries`
        )}
      </div>

      {/* Selected Winery Popup */}
      {selectedWinery && (
        <div 
          className="absolute inset-0 bg-black/20 flex items-center justify-center z-20"
          onClick={() => setSelectedWinery(null)}
        >
          <div 
            className="p-4 max-w-[90vw]" 
            onClick={e => e.stopPropagation()}
          >
            <WineryPopup 
              winery={selectedWinery}
              onClose={() => setSelectedWinery(null)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InteractiveMap;
