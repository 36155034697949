import RoleBasedNavigation from '../components/navigation/RoleBasedNavigation';

const WineryAdminDashboard = () => {
    return (
        <div className="flex">
            <RoleBasedNavigation />
            <main className="flex-1 bg-gray-50 min-h-screen">
                <h2 className="text-2xl font-bold p-6">Winery Management</h2>
                <div className="p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {/* Analytics */}
                    <div className="bg-white rounded-lg shadow p-6">
                        <h3 className="font-semibold mb-4">Performance Overview</h3>
                        {/* Analytics content */}
                    </div>

                    {/* Staff Management */}
                    <div className="bg-white rounded-lg shadow p-6">
                        <h3 className="font-semibold mb-4">Staff Overview</h3>
                        {/* Staff content */}
                    </div>

                    {/* Wine Management */}
                    <div className="bg-white rounded-lg shadow p-6">
                        <h3 className="font-semibold mb-4">Wine Inventory</h3>
                        {/* Wine content */}
                    </div>
                </div>
            </main>
        </div>
    );
};

export {
    WineryAdminDashboard
};